import { useQuery } from '@tanstack/react-query';
import Api from '../../../../api';
import { Params } from '../types';
import { useApi } from '../../hooks/useApi';

const fetchParams = (api: Api) => async () => {
	const response = await api.fetchParams();
	return response.data as Params;
};

export const useParamsQuery = () => {
	const api = useApi();
	return useQuery({
		queryKey: ['params'],
		queryFn: fetchParams(api),
		retry: false,
	});
};
