import { useAuth } from '../../hooks/useAuth';
import { CustomPage } from '../../pages/CustomPage';
import { useApi } from '../../hooks/useApi';
import { useParams } from 'react-router-dom';
import { useDesign } from '../../hooks/useDesign';
import { CustomPageBlocksProvider } from './useBlocks';
import { CustomPagesPanel } from './CustomPagesPanel';
import { EditPanel } from './EditPanel';
import { useQuery } from '@tanstack/react-query';

interface Props {
	children: React.ReactNode;
}

const Editor = ({ children }: Props) => {
	const api = useApi();
	const { shortid } = useParams();

	const customPagesQuery = useQuery({
		queryKey: ['custom_pages'],
		queryFn: async () => {
			const { data } = await api.fetchCustomPages();
			return data as CustomPage[];
		},
	});

	const currentPage = customPagesQuery.data?.find(
		(page) => page.short_id === shortid
	);

	return (
		<div style={{ display: 'flex', minHeight: 'calc(100vh - 32px)' }}>
			<CustomPagesPanel
				currentPageShortId={shortid}
				customPages={customPagesQuery.data ?? []}
			/>
			<CustomPageBlocksProvider key={shortid}>
				<div
					style={{
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						minHeight: '100%',
						position: 'relative',
					}}
				>
					{customPagesQuery.isSuccess && children}
				</div>
				{shortid && currentPage && (
					<EditPanel key={currentPage.id} page={currentPage} />
				)}
			</CustomPageBlocksProvider>
		</div>
	);
};

export const EditorContainer = ({ children }: Props) => {
	const { isAdmin } = useAuth();
	const { isDesignMode } = useDesign();

	if (isAdmin && isDesignMode) {
		return <Editor>{children}</Editor>;
	}

	return <>{children}</>;
};
