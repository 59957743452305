import { useState } from 'react';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

const paymentMethod = {
	NONE: 'none',
	CREDIT_CARD: 'credit_card',
	EXTERNAL: 'external',
};

interface Props {
	forceCreditCard: boolean;
	creditCardMethod: React.ReactNode;
	externalMethod: React.ReactNode;
}

const PaymentMethodSelector: React.FC<Props> = (props) => {
	const [selectedMethod, setSelectedMethod] = useState(paymentMethod.NONE);
	const { __ } = useTranslate();
	const selectPaymentMethod = (method: string) => {
		setSelectedMethod(method);
	};

	const { forceCreditCard, creditCardMethod, externalMethod } = props;

	if (forceCreditCard) {
		return creditCardMethod;
	}

	return (
		<div>
			<p className="text-center">{__('Choose a payment method')}</p>

			<div className="payment-methods">
				<div
					role="button"
					className={`payment-method btn-ticketing-1 ${
						selectedMethod === paymentMethod.CREDIT_CARD ? 'selected ' : ''
					}`}
					onClick={() => selectPaymentMethod(paymentMethod.CREDIT_CARD)}
				>
					<i className="logo fa fa-credit-card" aria-hidden="true" />
					{__('Online payment')}
				</div>
				<div
					role="button"
					className={`payment-method btn-ticketing-1 ${
						selectedMethod === paymentMethod.EXTERNAL ? 'selected ' : ''
					}`}
					onClick={() => selectPaymentMethod(paymentMethod.EXTERNAL)}
				>
					<i className="logo fa fa-question" aria-hidden="true" />
					{__('Other payment method')}
				</div>
			</div>

			{selectedMethod === paymentMethod.CREDIT_CARD && creditCardMethod}

			{selectedMethod === paymentMethod.EXTERNAL && externalMethod}
		</div>
	);
};

export default PaymentMethodSelector;
