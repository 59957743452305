import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';

export const Legals = () => {
	const event = useEvent();
	const { __ } = useTranslate();

	useDocTitle(event.name);

	return (
		<section>
			<div className="container section-container">
				<h2 className="section-heading custom-font-title">
					{__('Legal Mentions')}
				</h2>

				<div>
					<p>
						Conformément aux dispositions de l’article 6 III-1 de la loi n°
						2004-575 du 21 juin 2004 pour la confiance dans l’économie
						numérique, nous vous informons que :
					</p>
					<p>
						Le présent site est la propriété de la société&nbsp;
						<strong>SAS CORPO’EVENTS</strong>&nbsp;au capital de
						63&nbsp;320&nbsp;€, immatriculée au Registre du Commerce et des
						Sociétés de Montpellier sous le numéro 484 434 477, dont le siège
						est situé 1415, Avenue Albert Einstein 34000 MONTPELLIER , n° TVA :
						FR70 484 434 477. Immatriculation agent de voyages&nbsp;:
						IM013100060
					</p>
					<p>
						<strong>
							Le webmaster du site WEB est la société&nbsp;SAS CORPO’EVENTS
						</strong>
					</p>
					<p>
						1415, Avenue Albert Einstein 34000 MONTPELLIER
						<br />
						E-mail : contact@corpo-events.fr
						<br />
						Site : www.corpo-events.fr
					</p>

					<hr style={{ marginTop: 50, marginBottom: 50 }} />

					<p>
						L’utilisateur reconnaît avoir pris connaissance des présentes
						conditions d’utilisation et s’engage à les respecter.
					</p>
					<p>
						L’utilisateur du site web reconnaît disposer de la compétence et des
						moyens nécessaires pour accéder et utiliser ce site.
					</p>
					<p>
						L’utilisateur des sites
						web&nbsp;www.invityou.com,&nbsp;www.corpo-events.fr,&nbsp;www.sport-coach.fr,&nbsp;www.seminup.fr,&nbsp;www.french-dmc.com&nbsp;et&nbsp;www.soirees-parisiennes.fr&nbsp;reconnaît
						avoir vérifié que la configuration informatique utilisée ne contient
						aucun virus et qu’elle est en parfait état de fonctionnement.
					</p>
					<p>
						L’exploitant du site web met tout en œuvre pour offrir aux
						utilisateurs des informations et/ou outils disponibles et vérifiés
						mais ne saurait être tenu pour responsable des erreurs, d’une
						absence de disponibilité des informations et/ou de la présence de
						virus sur son site.
					</p>
					<p>
						Les informations fournies par l’exploitant du site web le sont à
						titre indicatif et ne sauraient dispenser l’utilisateur d’une
						analyse complémentaire et personnalisée. L’exploitant du site web ne
						saurait garantir l’exactitude, la complétude, l’actualité des
						informations diffusées sur son site.
					</p>
					<p>
						En conséquence, l’utilisateur reconnaît utiliser ces informations
						sous sa responsabilité exclusive.
					</p>
					<p>
						En conformité avec les dispositions de la loi 6 janvier 1978
						relative à l’informatique, aux fichiers et aux libertés modifiée,
						les traitements automatisés de données nominatives réalisés à partir
						des sites
						web&nbsp;www.invityou.com,&nbsp;www.corpo-events.fr,&nbsp;www.sport-coach.fr,&nbsp;www.seminup.fr,&nbsp;www.french-dmc.com&nbsp;et&nbsp;www.soirees-parisiennes.fr&nbsp;ont
						fait l’objet d’une déclaration auprès de la Commission Nationale de
						l’Informatique et des Libertés (CNIL) qui a délivré un récépissé
						sous le numéro n° 1245532.
					</p>
					<p>
						L’utilisateur est notamment informé que, conformément à l’article 32
						de la loi informatique, fichiers et libertés du 6 janvier 1978
						modifiée, les informations communiquées par l’utilisateur du fait
						des formulaires présents sur le site, sont nécessaires pour répondre
						à sa demande et sont destinés à l’exploitant du site web,
						responsable du traitement à des fins de gestion administrative et
						commerciale.
					</p>
					<p>
						L’utilisateur est informé qu’il dispose d’un droit d’accès et de
						rectification portant sur les données le concernant en écrivant à
						l’exploitant du site web dont l’adresse est la suivante : 1415,
						Avenue Albert Einstein 34000 MONTPELLIER ou en envoyant un e-mail à
						l’adresse suivante : marc@corpo-events.fr
					</p>

					<hr style={{ marginTop: 50, marginBottom: 50 }} />

					<h3 className="text-center" style={{ color: 'var(--titlevariant)' }}>
						Cookies
					</h3>

					<p>
						L’utilisateur est informé que, lors de ses visites sur le site, un
						cookie peut s’installer automatiquement sur son logiciel de
						navigation.
					</p>
					<p>
						Le cookie est un bloc de données qui ne permet pas d’identifier les
						utilisateurs mais sert à enregistrer des informations relatives à la
						navigation de celui-ci sur le site.
					</p>
					<p>
						Le paramétrage du logiciel de navigation permet d’informer de la
						présence de cookie et éventuellement de la refuser de la manière
						décrite à l’adresse suivante : &nbsp;www.cnil.fr.
					</p>
					<p>
						L’utilisateur dispose d’un droit d’accès, de retrait et de
						modification des données personnelles communiquées par le biais des
						cookies dans les conditions indiquées ci-dessus.
					</p>
					<p>
						Les utilisateurs des sites
						web&nbsp;www.invityou.com,&nbsp;www.corpo-events.fr,&nbsp;www.sport-coach.fr,&nbsp;www.seminup.fr,&nbsp;www.french-dmc.com&nbsp;et&nbsp;www.soirees-parisiennes.fr&nbsp;sont
						tenus de respecter les dispositions de la loi relative à
						l’informatique, aux fichiers et aux libertés, dont la violation est
						passible de sanctions pénales.
					</p>
					<p>
						Ils doivent notamment s’abstenir, s’agissant des informations
						nominatives auxquelles ils accèdent, de toute collecte, de toute
						utilisation détournée et, d’une manière générale, de tout acte
						susceptible de porter atteinte à la vie privée ou la réputation des
						personnes.
					</p>

					<h6
						className="text-center"
						style={{ marginTop: '1.5rem', color: 'var(--titlevariant)' }}
					>
						Cookies internes nécessaires au site pour fonctionner
					</h6>

					<p>
						Ces cookies permettent au site de fonctionner de manière optimale.
						Vous pouvez vous y opposer et les supprimer en utilisant les
						paramètres de votre navigateur, cependant votre expérience
						utilisateur risque d’être dégradée.
					</p>

					<table className="table">
						<thead>
							<tr>
								<th>Nom du cookie</th>
								<th>Finalité</th>
								<th>Durée de conservation</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>CAKEPHP</td>
								<td>Composant PHP pour la gestion de sessions</td>
								<td>25 jours</td>
							</tr>
						</tbody>
					</table>

					<hr style={{ marginTop: 50, marginBottom: 50 }} />

					<p>
						La structure générale, ainsi que les logiciels, textes, images
						animées ou non, sons, savoir-faire…, et tous les autres éléments
						composant le site sont la propriété exclusive de l’exploitant du
						site web.
					</p>
					<p>
						Toute représentation totale ou partielle de ce site par quelque
						société que ce soit, sans l’autorisation expresse de l’exploitant du
						site, est interdite et constituerait une contrefaçon sanctionnée par
						les articles L.335-2 et suivant du Code de la propriété
						intellectuelle.
					</p>
					<p>
						Il est en de même des bases de données figurant, le cas échéant, sur
						les sites
						web&nbsp;www.invityou.com,&nbsp;www.corpo-events.fr,&nbsp;www.sport-coach.fr,&nbsp;www.seminup.fr,&nbsp;www.french-dmc.com&nbsp;et&nbsp;www.soirees-parisiennes.fr,
						qui sont protégées par les dispositions de la loi du 1er juillet
						1998 portant transposition dans le Code de la propriété
						intellectuelle de la directive européenne du 11 mars 1996 relative à
						la protection juridique des bases de données.
					</p>
					<p>
						Les marques de l’exploitant du site web et de ses partenaires, ainsi
						que les logos figurant sur le site sont des marques semi-figuratives
						ou non et sont déposées.
					</p>
					<p>
						Toute reproduction totale ou partielle de ces marques ou de ces
						logos effectués à partir des éléments du site sans l’autorisation
						expresse de l’exploitant du site web est donc prohibée, au sens de
						l’article L.713-2 du Code de la propriété intellectuelle.
					</p>
					<p>
						Les utilisateurs et visiteurs du site web sont informés que
						la&nbsp;SAS CORPO’EVENTS&nbsp;n’est pas responsable des relations
						pouvant s’établir entre un partenaire et un client final.
					</p>
					<p>
						Les utilisateurs et visiteurs du site web ne peuvent mettre en place
						un hyperlien en direction de ce site sans l’autorisation expresse et
						préalable de l’exploitant du site web.
					</p>
					<p>
						L’exploitant du site web ne saurait être responsable de l’accès par
						les utilisateurs via les liens hypertextes mis en place dans le
						cadre du site web en direction d’autres ressources présentes sur le
						réseau internet.
					</p>
				</div>
			</div>
		</section>
	);
};
