import { useTranslate } from '../../../shared/hooks/useTranslate';
import { Link } from 'react-router-dom';
import { Recommendation } from '@invityou/types/bin/api/Users';

interface Props {
	recommendations: Recommendation[];
}

export const Recommendations = ({ recommendations }: Props) => {
	const { __ } = useTranslate();

	return (
		<div id="recommendations">
			<div className="form" id="recommendations-list">
				<h2 className="form-title">{__('Recommendations')}</h2>

				{recommendations.length === 0 ? (
					<p style={{ marginBottom: 50 }}>
						{__('You have not made any recommendation yet.')}
					</p>
				) : (
					<ul className="list-inline list-card">
						{recommendations.map((reco) => {
							return (
								<li key={reco.id}>
									<div className="card card-recommendation">
										<div className="card-content">
											<div>
												<strong>
													{reco.lastname} {reco.firstname}
												</strong>
											</div>
											<div className="card__mail">{reco.mail}</div>
											<div className="card__job">
												{reco.company}
												<br />
												{reco.job_title}
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				)}

				<div className="form-action">
					<Link to="recommendation#start" className="btn btn-secondary">
						{__('Make a recommendation')}
					</Link>
				</div>
			</div>
		</div>
	);
};
