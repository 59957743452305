import { useState } from 'react';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { User } from '@invityou/types';
import { useDebounce } from '../../../shared/hooks/useDebounce';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const Participants = () => {
	const event = useEvent();
	const { prefix } = useAbsolutePath();
	const { __ } = useTranslate();

	const participantsTitle =
		event.template.participants_title &&
		event.template.participants_title !== ''
			? event.template.participants_title
			: __('Attendees');

	useDocTitle(`${participantsTitle} | ${event.name}`);

	const [search, setSearch] = useState('');

	const debouncedSearch = useDebounce(search, 500);

	const options = { search: debouncedSearch };

	const listUsersQuery = useQuery({
		queryKey: ['list_users', prefix, options],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/listJson`, {
				params: options,
			});

			return data as Pick<
				User,
				'id' | 'firstname' | 'lastname' | 'company' | 'job_title'
			>[];
		},
		placeholderData: keepPreviousData,
		retry: false,
	});

	const users = listUsersQuery.data ?? [];

	const { isLoggedIn } = useAuth();
	if (!event.params.isOpenEvent && !isLoggedIn) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	return (
		<section className="section-long">
			<div
				id="participants"
				className="container section-container custom-font-content"
			>
				{!event.template.participants_block ? (
					<p className="custom-font-content">
						{__('The list of attendees is not available.')}
					</p>
				) : (
					<>
						<h2 className="section-heading custom-font-title">
							{participantsTitle} ({users.length})
						</h2>

						<div className="form row">
							<div className="col-sm-8 col-sm-offset-2">
								<div className="form-group has-feedback">
									<div className="input-group">
										<span className="input-group-addon">
											<i className="fa fa-search fa-fw" aria-hidden="true"></i>
										</span>
										<input
											type="text"
											className="form-control"
											name="search"
											value={search}
											onChange={(e) => {
												setSearch(e.target.value);
											}}
											placeholder={__('Search for a name')}
										/>
									</div>
									<span className="icon-helper form-control-feedback hide">
										<i className="fa fa-times fa-fw" aria-hidden="true"></i>
									</span>
								</div>
							</div>
						</div>

						{users.length === 0 && search !== '' ? (
							<p className="participants-empty">
								{__('There are no attendees corresponding to your search.')}
							</p>
						) : users.length === 0 ? (
							<p className="participants-empty">
								{__('There are no attendees.')}
							</p>
						) : (
							<ul className="participants-list">
								{users.map((user) => {
									return (
										<li key={user.id} className="col-3">
											<p className="participant-name">
												{user.firstname} {user.lastname}
											</p>
											<p className="participant-details">
												<span className="participant-job">
													{user.job_title}
												</span>
												{user.job_title && user.company && <> - </>}
												<span className="participant-company">
													{user.company}
												</span>
											</p>
										</li>
									);
								})}
							</ul>
						)}
					</>
				)}
			</div>
		</section>
	);
};
