import { useQuery } from '@tanstack/react-query';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import axios from 'axios';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { parseISO } from 'date-fns';

const OrderStatusBadge = ({ status }: { status: string }) => {
	const { __ } = useTranslate();

	let statusText = status;
	switch (status) {
		case 'completed':
			statusText = __('Validated');
			break;
		case 'refunded':
			statusText = __('Refunded');
			break;
		case 'cancelled':
			statusText = __('Cancelled');
			break;
		case 'awaiting_payment':
			statusText = __('Awaiting payment');
			break;
	}

	return (
		<span className={`badge badge-${status} pull-right`}>{statusText}</span>
	);
};

export const UserOrders = () => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { user } = useAuth();

	useDocTitle(__('My orders'));

	const ordersQuery = useQuery({
		queryKey: ['user_orders', prefix],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/ordersJson`);
			return data as {
				orders: {
					id: string;
					status: string;
					amount: number;
					items: {
						user: { firstname: string; lastname: string };
						ticket: { full_name: string };
					}[];
					invoice: { pdf_url: string } | null;
					created: string;
				}[];
			};
		},
		refetchOnWindowFocus: false,
	});

	if (!user) {
		throw new Error('User not found.');
	}

	return (
		<section>
			<div className="container section-container">
				<div className="form">
					<h2 className="form-title">
						<ol className="breadcrumb">
							<li>
								<Link to={`${prefix}/member#start`}>
									{user.firstname || user.lastname
										? `${user.firstname} ${user.lastname}`
										: user.mail}
								</Link>
							</li>
							<li>{__('Orders')}</li>
						</ol>
					</h2>

					{ordersQuery.isSuccess && (
						<ul className="list-inline list-card">
							{ordersQuery.data.orders.map((order) => {
								return (
									<li key={order.id}>
										<div className="card">
											<div className="card-content text-muted">
												{new Intl.DateTimeFormat(lang, {
													dateStyle: 'short',
													timeStyle: 'short',
												}).format(parseISO(order.created))}{' '}
												<OrderStatusBadge status={order.status} />
											</div>

											<div className="card-content">
												<ul className="items">
													{order.items.map((item, index) => {
														return (
															<li key={index} className="item">
																- 1 {item.ticket.full_name} {__('for')}{' '}
																{item.user.firstname} {item.user.lastname}
															</li>
														);
													})}
												</ul>
											</div>

											<div className="card-content">
												{__('Total:')}{' '}
												{new Intl.NumberFormat(lang, {
													style: 'currency',
													currency: 'EUR',
												}).format(order.amount / 100)}
												<span className="pull-right">
													{order.status === 'completed' &&
														order.invoice &&
														order.invoice.pdf_url !== '' && (
															<a
																href={order.invoice.pdf_url}
																target="_blank"
																rel="noreferrer"
															>
																{__('Download invoice')}
															</a>
														)}
												</span>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</section>
	);
};
