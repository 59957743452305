import ReCAPTCHA from 'react-google-recaptcha';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { FormEvent, createRef, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Alert from '../components/Alert';
import { GOOGLE_CLIENT_KEY } from '../config';
import { SubmitButton } from '../components/SubmitButton';
import {
	getServerErrorMessage,
	getValidationErrors,
} from '../../../shared/utils';

const FormInput = ({
	name,
	label,
	type,
	errors,
	required = false,
	value,
	onChange,
}: {
	name: string;
	label: string;
	type: 'text' | 'textarea';
	errors: Record<string, string | undefined> | null;
	required?: boolean;
	value: string;
	onChange: (value: string) => void;
}) => {
	return (
		<div
			className={`form-group ${required ? 'required' : ''} ${
				errors && errors[name] ? 'form-error' : ''
			}`}
		>
			<label className="control-label" htmlFor={name}>
				{label}
			</label>
			<div className="form-col">
				{type === 'text' ? (
					<input
						name={name}
						type={type}
						id={name}
						className="form-control"
						required={required}
						value={value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					/>
				) : (
					<textarea
						name={name}
						id={name}
						className="form-control"
						required={required}
						value={value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					></textarea>
				)}
				{errors && errors[name] && (
					<div className="help-block text-error">{errors[name]}</div>
				)}
			</div>
		</div>
	);
};

export const Contact = () => {
	const event = useEvent();
	const { isLoggedIn } = useAuth();
	const { __ } = useTranslate();

	const contactTitle =
		event.template.organizer_title && event.template.organizer_title !== ''
			? event.template.organizer_title
			: __('Contact the organizer');

	useDocTitle(`${contactTitle} | ${event.name}`);

	const recaptchaRef = createRef<ReCAPTCHA>();

	const [error, setError] = useState<string | null>(null);

	const resetError = () => {
		setError(null);
	};

	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const [formValues, setFormValues] = useState<
		Record<string, string | undefined>
	>({});

	const sendContactFormMutation = useMutation({
		mutationFn: async (formValue: typeof formValues) => {
			await axios.post(`/${event.slug}/messages/sendMessageJson`, formValue);
		},
		onSuccess: () => {
			setSuccessMessage(__('Your message was sent to the organizers.'));
			setFormValues({});
		},
		onError: (err) => {
			setError(getServerErrorMessage(err, err.message));
		},
		onSettled: () => {
			recaptchaRef.current?.reset();
		},
	});

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		resetError();

		const recaptcha = recaptchaRef.current?.getValue();

		if (!recaptcha) {
			setError(__('Please fill the captcha correctly.'));
			return;
		}

		sendContactFormMutation.mutate({ ...formValues, recaptcha });
	};

	const errors = getValidationErrors(sendContactFormMutation.error);

	return (
		<section>
			<div className="container section-container">
				<form method="post" className="form" onSubmit={handleSubmit}>
					{error && (
						<Alert type="danger" dismissible>
							{error}
						</Alert>
					)}

					{successMessage && (
						<Alert type="success" dismissible>
							{successMessage}
						</Alert>
					)}

					<h2 className="form-title">{contactTitle}</h2>

					{!isLoggedIn && (
						<>
							<FormInput
								name="mail"
								label={__('Email')}
								type="text"
								errors={errors}
								required
								value={formValues['mail'] ?? ''}
								onChange={(value) => {
									setFormValues((values) => {
										return { ...values, mail: value };
									});
								}}
							/>

							<FormInput
								name="civility"
								label={__('Civility')}
								type="text"
								errors={errors}
								value={formValues['civility'] ?? ''}
								onChange={(value) => {
									setFormValues((values) => {
										return { ...values, civility: value };
									});
								}}
							/>

							<FormInput
								name="firstname"
								label={__('First Name')}
								type="text"
								errors={errors}
								required
								value={formValues['firstname'] ?? ''}
								onChange={(value) => {
									setFormValues((values) => {
										return { ...values, firstname: value };
									});
								}}
							/>

							<FormInput
								name="lastname"
								label={__('Last Name')}
								type="text"
								errors={errors}
								required
								value={formValues['lastname'] ?? ''}
								onChange={(value) => {
									setFormValues((values) => {
										return { ...values, lastname: value };
									});
								}}
							/>
						</>
					)}

					<FormInput
						name="phone"
						label={__('Phone')}
						type="text"
						errors={errors}
						value={formValues['phone'] ?? ''}
						onChange={(value) => {
							setFormValues((values) => {
								return { ...values, phone: value };
							});
						}}
					/>

					<FormInput
						name="subject"
						label={__('Subject')}
						type="text"
						errors={errors}
						required
						value={formValues['subject'] ?? ''}
						onChange={(value) => {
							setFormValues((values) => {
								return { ...values, subject: value };
							});
						}}
					/>

					<FormInput
						name="content"
						label={__('Message')}
						type="textarea"
						errors={errors}
						required
						value={formValues['content'] ?? ''}
						onChange={(value) => {
							setFormValues((values) => {
								return { ...values, content: value };
							});
						}}
					/>

					<div className="form-group">
						<label className="control-label"></label>
						<div className="form-col">
							<ReCAPTCHA sitekey={GOOGLE_CLIENT_KEY} ref={recaptchaRef} />
						</div>
					</div>

					<div className="form-action">
						<SubmitButton loading={sendContactFormMutation.isPending}>
							{__('Send')}
						</SubmitButton>
					</div>
				</form>
			</div>
		</section>
	);
};
