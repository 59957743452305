import ReCAPTCHA from 'react-google-recaptcha';
import FormGroup from '../../../shared/components/FormGroup';
import { DetailsBlock } from '../components/DetailsBlock';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { FormEvent, createRef, useState } from 'react';
import { GOOGLE_CLIENT_KEY } from '../config';
import Alert from '../components/Alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getServerErrorMessage } from '../../../shared/utils';
import axios from 'axios';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { SubmitButton } from '../components/SubmitButton';
import { ContactOrganizerBlock } from '../components/OrganizerBlock';
import { getCakeLang } from '../../../i18n';
import { useAuth } from '../hooks/useAuth';

const RegisterForm = () => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const navigate = useNavigate();

	const recaptchaRef = createRef<ReCAPTCHA>();

	const [error, setError] = useState<string | null>(null);

	const resetError = () => {
		setError(null);
	};

	const [mail, setMail] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);

	const queryClient = useQueryClient();
	const sendRegisterFormMutation = useMutation({
		mutationFn: async (formData: {
			recaptcha: string;
			mail: string;
			firstname: string;
			lastname: string;
			terms: boolean;
		}) => {
			await axios.post(`${prefix}/users/loginRegisterJson`, formData);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['event_auth'] });
			navigate(`${prefix}/register`);
		},
		onError: (err) => {
			setError(getServerErrorMessage(err, err.message));
		},
		onSettled: () => {
			recaptchaRef.current?.reset();
		},
	});

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		resetError();

		if (!privacyPolicyCheckbox) {
			setError(
				__(
					'Please indicate that you have read and agree to the Privacy Policy.'
				)
			);
			return;
		}

		const recaptcha = recaptchaRef.current?.getValue();

		if (!recaptcha) {
			setError(__('Please fill the captcha correctly.'));
			return;
		}

		sendRegisterFormMutation.mutate({
			recaptcha,
			mail,
			firstname,
			lastname,
			terms: privacyPolicyCheckbox,
		});
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<h2 className="form-title">{__('You wish to participate ?')}</h2>

			{error && (
				<Alert type="danger" dismissible>
					{error}
				</Alert>
			)}

			<FormGroup label={__('Email')} name="mail" required>
				<div className="form-col">
					<input
						name="mail"
						type="email"
						className="form-control"
						value={mail}
						onChange={(e) => {
							setMail(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<FormGroup label={__('First Name')} name="firstname" required>
				<div className="form-col">
					<input
						name="firstname"
						type="text"
						className="form-control"
						value={firstname}
						onChange={(e) => {
							setFirstname(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<FormGroup label={__('Last Name')} name="lastname" required>
				<div className="form-col">
					<input
						name="lastname"
						type="text"
						className="form-control"
						value={lastname}
						onChange={(e) => {
							setLastname(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<div className="form-group">
				<legend className="control-label"></legend>
				<div className="form-col">
					<div className="checkbox">
						<label>
							<input
								name="terms"
								type="checkbox"
								checked={privacyPolicyCheckbox}
								onChange={(e) => {
									setPrivacyPolicyCheckbox(e.target.checked);
								}}
							/>
							{__('By checking this case, I acknowledge having read the')}{' '}
							<a
								href={`/files/legal/pp_${getCakeLang(lang)}.pdf`}
								target="_blank"
								rel="noreferrer"
							>
								{__('Privacy Policy')}
							</a>
							.
						</label>
					</div>
				</div>
			</div>

			<div className="form-group">
				<label className="control-label"></label>
				<div className="form-col">
					<ReCAPTCHA sitekey={GOOGLE_CLIENT_KEY} ref={recaptchaRef} />
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12">
					<div className="form-inline text-right">
						<div className="form-group form-action">
							<SubmitButton loading={sendRegisterFormMutation.isPending}>
								{__('Continue')}
							</SubmitButton>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

interface LoginFormProps {
	isTokenExpired?: boolean;
}

const LoginForm = ({ isTokenExpired }: LoginFormProps) => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const navigate = useNavigate();

	const recaptchaRef = createRef<ReCAPTCHA>();

	const [error, setError] = useState<string | null>(null);

	const resetError = () => {
		setError(null);
	};

	const [mail, setMail] = useState<string>('');

	const sendLoginFormMutation = useMutation({
		mutationFn: async (formData: { mail: string }) => {
			await axios.post(`${prefix}/users/loginJson`, formData);
		},
		onSuccess: () => {
			navigate(`${prefix}/sending-token?mail=${mail}`);
		},
		onError: (err) => {
			setError(getServerErrorMessage(err, err.message));
		},
		onSettled: () => {
			recaptchaRef.current?.reset();
		},
	});

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		resetError();

		sendLoginFormMutation.mutate({ mail });
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<h2 id="start-login" className="form-title">
				{!isTokenExpired
					? __('I am already registered ?')
					: __('Ask for a new login link')}
			</h2>

			{error && (
				<Alert type="danger" dismissible>
					{error}
				</Alert>
			)}

			<FormGroup label={__('Email')} name="mail" required>
				<div className="form-col">
					<input
						name="mail"
						type="email"
						className="form-control"
						placeholder={__('Enter your email address')}
						value={mail}
						onChange={(e) => {
							setMail(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<div className="row">
				<div className="col-sm-12">
					<div className="form-inline text-right">
						<div className="form-group form-action">
							<SubmitButton loading={sendLoginFormMutation.isPending}>
								{__('Receive my connection link')}
							</SubmitButton>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export const Login = () => {
	const event = useEvent();
	const { __ } = useTranslate();

	useDocTitle(__('Login / Registration'));

	const { isLoggedIn } = useAuth();
	const { prefix } = useAbsolutePath();

	const [searchParams] = useSearchParams();
	const state = searchParams.get('state');
	const isTokenExpired = state === 'expired-token';

	if (isLoggedIn) {
		return <Navigate to={`${prefix}/member#start`} replace />;
	}

	return (
		<>
			{event.params.redirectToRegistration && <DetailsBlock />}

			<section>
				<div className="container section-container">
					{event.params.openInscriptions && !isTokenExpired && <RegisterForm />}
					<LoginForm isTokenExpired={isTokenExpired} />
				</div>
			</section>

			{event.params.redirectToRegistration && <ContactOrganizerBlock />}
		</>
	);
};
