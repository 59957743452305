import { useState } from 'react';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { SubmitButton } from '../SubmitButton';
import FormGroup from '../../../../shared/components/FormGroup';
import { slugify } from '../../../../admin/events/utils';
import { useEvent } from '../../hooks/useEvent';
import { CustomPage } from '../../pages/CustomPage';
import { useApi } from '../../hooks/useApi';
import {
	setTranslation,
	translateWithDefaultFallback,
} from '../../magicform/utils/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useToast } from '../../hooks/useToast';
import {
	ValidationErrors,
	validateFormData,
} from '../../../../shared/validate';
import { LayoutConfig } from '../../hooks/useLayoutConfig';
import { CreatePageFormValidationSchema } from './types';
import { getServerErrorMessage } from '../../../../shared/utils';
import { css } from '@emotion/css';

interface Props {
	customPages: CustomPage[];
	currentPageShortId: string | undefined;
}

export const CustomPagesPanel = ({
	currentPageShortId,
	customPages,
}: Props) => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const api = useApi();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const toast = useToast();

	const createCustomPageMutation = useMutation({
		mutationFn: async (body: Partial<CustomPage>) => {
			const { data } = await api.createCustomPage(body);
			return data as CustomPage;
		},
		onSuccess: (page) => {
			queryClient.invalidateQueries({ queryKey: ['custom_pages'] });

			// Update nav
			const el = document.querySelector('#layout-config');
			if (el) {
				const textContent = el.textContent as string;
				const layoutConfigData = JSON.parse(textContent) as LayoutConfig;
				const newCustomPagesNav = [...layoutConfigData.customPagesNav];
				newCustomPagesNav.push({
					short_id: page.short_id,
					slug: page.slug,
					nav_title: page.nav_title,
				});
				el.textContent = JSON.stringify({
					...layoutConfigData,
					customPagesNav: newCustomPagesNav,
				});
				queryClient.invalidateQueries({ queryKey: ['layout_config'] });
			}

			toast(__('Page created.'), 'success');
			navigate(`${prefix}/pages/${page.short_id}/${page.slug}`);
		},
	});

	const [displayCreateCustomPageModal, setDisplayCustomCreatePageModal] =
		useState(false);
	const closeModal = () => {
		setDisplayCustomCreatePageModal(false);
	};

	const [validationErrors, setValidationErrors] =
		useState<ValidationErrors | null>(null);

	const validatePage = (formData: unknown) =>
		validateFormData(formData, CreatePageFormValidationSchema, __);

	const [newTitle, setNewTitle] = useState('');
	const [newSlug, setNewSlug] = useState('');

	const resetForm = () => {
		setNewTitle('');
		setNewSlug('');
		setValidationErrors(null);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const title = setTranslation({}, lang, newTitle);
		const body = { title: title, nav_title: title, slug: newSlug };

		const errors = validatePage(body);
		if (errors) {
			setValidationErrors(errors);
			return;
		} else {
			setValidationErrors(null);
		}

		createCustomPageMutation.mutate(body, {
			onSuccess: () => {
				closeModal();
				resetForm();
			},
			onError: (err) => {
				toast(
					getServerErrorMessage(err, __('An error has ocurred.')),
					'danger'
				);
			},
		});
	};

	const event = useEvent();
	const url = event.event_url;

	return (
		<div
			className="admin-ui"
			style={{
				backgroundColor: '#ffffff',
				maxWidth: 230,
				alignSelf: 'stretch',
				padding: 10,
				borderRight: '1px solid #ddd',
			}}
		>
			<Modal
				isOpen={displayCreateCustomPageModal}
				onRequestClose={closeModal}
				extraClassName="admin-ui"
			>
				<ModalHeader>{__('Create a new page')}</ModalHeader>
				<ModalBody>
					<form id="new-custom-page" onSubmit={handleSubmit}>
						<FormGroup
							id="title"
							name="title"
							label={__('Name')}
							required
							validationErrors={validationErrors}
						>
							<input
								type="text"
								className="form-control"
								name="title"
								value={newTitle}
								onChange={(e) => {
									const name = e.target.value;
									setNewTitle(name);
									setNewSlug(slugify(name));
								}}
							/>
						</FormGroup>

						<FormGroup
							id="slug"
							name="slug"
							label={__('URL of the page')}
							required
							validationErrors={validationErrors}
						>
							<div className="input-group">
								<span className="input-group-addon">{`${url}/pages/xxx/`}</span>
								<input
									type="text"
									className="form-control"
									name="slug"
									value={newSlug}
									onChange={(e) => {
										const slug = e.target.value;
										setNewSlug(slugify(slug));
									}}
								/>
							</div>
						</FormGroup>
					</form>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-default"
						onClick={closeModal}
					>
						{__('Cancel')}
					</button>
					<SubmitButton
						type="warning"
						form="new-custom-page"
						loading={createCustomPageMutation.isPending}
					>
						{__('Create page')}
					</SubmitButton>
				</ModalFooter>
			</Modal>

			<ul
				className={css`
					margin-bottom: 10px;

					li {
						margin-bottom: 5px;
					}

					a {
						display: block;
						padding: 10px;
						text-decoration: none;
						border-radius: 4px;
					}

					a:hover {
						background-color: #eee;
					}

					a.active {
						background-color: #4c6480;
						color: #fff;
					}
				`}
			>
				<li>
					<Link to={prefix} className={!currentPageShortId ? 'active' : ''}>
						{__('Home')}
					</Link>
				</li>
				{customPages.map((page) => {
					return (
						<li key={page.id}>
							<Link
								to={`${prefix}/pages/${page.short_id}/${page.slug}`}
								className={currentPageShortId === page.short_id ? 'active' : ''}
							>
								{translateWithDefaultFallback(page.title, lang)}
							</Link>
						</li>
					);
				})}
			</ul>

			<button
				className="btn btn-primary"
				onClick={() => setDisplayCustomCreatePageModal(true)}
			>
				<i className="fa fa-plus" aria-hidden="true"></i>{' '}
				<span className="visible-lg-inline">{__('Create a page')}</span>
			</button>
		</div>
	);
};
