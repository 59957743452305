import Popover from './Popover';

export const DropdownMenu: React.FC<{
	children?: React.ReactNode;
}> = ({ children }) => {
	return (
		<ul
			className="dropdown-menu"
			style={{ display: 'block', position: 'initial' }}
		>
			{children}
		</ul>
	);
};

interface Props {
	render: React.ReactNode;
	children: JSX.Element;
}

const Dropdown = ({ render, children }: Props) => {
	return (
		<Popover
			placement="bottom-start"
			trigger="click"
			offset={0}
			portal={false}
			render={({ close }) => <div onClick={() => close()}>{render}</div>}
		>
			{children}
		</Popover>
	);
};

export default Dropdown;
