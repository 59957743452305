import { useParams } from 'react-router-dom';
import { MagicForm } from '../magicform/components/MagicForm';
import { useApi } from '../hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { User } from '@invityou/types';
import { useCompanionsOptionsQuery } from './GodsonsAndRecommendations';
import { useTranslate } from '../../../shared/hooks/useTranslate';

export const AddOrEditGodson = () => {
	const { lang } = useTranslate();
	const api = useApi();
	const { id } = useParams();

	const guestQuery = useQuery({
		queryKey: ['user', id],
		queryFn: async () => {
			if (id) {
				const { data } = await api.fetchUser(id);
				return data as User;
			}

			return null;
		},
	});

	const companionsOptionsQuery = useCompanionsOptionsQuery(id);

	const generateUpdatedConfig = (formId: string, mail: string | null) => {
		return {
			formId,
			lang: lang,
			userId: id ?? null,
			isFormDisabled: false,
			maxCompanions: null,
			hidePreset: id ? ['User.mail'] : [],
			presetValues: {
				'User.mail': mail,
			},
		};
	};

	return guestQuery.isFetched &&
		companionsOptionsQuery.isSuccess &&
		companionsOptionsQuery.data.formId ? (
		<section className="section-long">
			<div className="container section-container">
				<MagicForm
					config={generateUpdatedConfig(
						companionsOptionsQuery.data.formId,
						guestQuery.data?.mail ?? null
					)}
				/>
			</div>
		</section>
	) : null;
};
