import { createContext, useContext } from 'react';
import { CustomPage } from '../pages/CustomPage';
import { useQuery } from '@tanstack/react-query';

export interface LayoutConfig {
	documentsCount: number;
	liveCheckins: [];
	partnersCount: number;
	photosCount: number;
	programCount: number;
	speakersCount: number;
	carousel: { url: string | null }[];
	customPagesNav: Pick<CustomPage, 'short_id' | 'slug' | 'nav_title'>[];
	isTokenExpired: boolean;
	hasJustLoggedIn: boolean;
}

const Context = createContext<LayoutConfig | null>(null);

interface Props {
	children?: React.ReactNode;
}

const LayoutConfigProvider = ({ children }: Props) => {
	const layoutConfigQuery = useQuery({
		queryKey: ['layout_config'],
		queryFn: async () => {
			const textContent = document.querySelector('#layout-config')?.textContent;

			if (!textContent) {
				throw new Error('#layout-config not found.');
			}

			const data = JSON.parse(textContent) as LayoutConfig;

			return data;
		},
	});

	return layoutConfigQuery.isSuccess ? (
		<Context.Provider value={layoutConfigQuery.data}>
			{children}
		</Context.Provider>
	) : null;
};

const useLayoutConfig = () => {
	const layoutConfig = useContext(Context);

	if (!layoutConfig) {
		throw new Error(
			'useLayoutConfig must be used within an LayoutConfigProvider'
		);
	}

	return layoutConfig;
};

export { LayoutConfigProvider, useLayoutConfig };
