import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../hooks/useApi';
import { Document } from '@invityou/types';
import { useAuth } from '../hooks/useAuth';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { Navigate } from 'react-router-dom';

export const Documents = () => {
	const event = useEvent();
	const { __ } = useTranslate();
	const api = useApi();

	const documentsTitle =
		event.template.documents_title && event.template.documents_title !== ''
			? event.template.documents_title
			: __('Documents');

	useDocTitle(`${documentsTitle} | ${event.name}`);

	const documentsQuery = useQuery({
		queryKey: ['documents', event.slug],
		queryFn: async () => {
			const { data } = await api.fetchDocuments();
			return data as Document[];
		},
	});

	const documents = documentsQuery.data ?? [];

	const { prefix } = useAbsolutePath();
	const { isLoggedIn } = useAuth();
	if (!event.params.isOpenEvent && !isLoggedIn) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	return (
		<section id="event-documents-single">
			<div className="container section-container">
				<h2 className="section-heading custom-font-title">{documentsTitle}</h2>

				<ul>
					{documents.map((document) => {
						const url = document.url.replaceAll('#', '%23');

						return (
							<li key={document.id}>
								<div className="title-documents">
									<a
										href={url}
										className="download-svg-documents"
										target="_blank"
										rel="noreferrer"
										download={document.name}
									>
										<i className="fa fa-download" aria-hidden="true"></i>{' '}
										{document.name}
									</a>
								</div>

								{document.description && (
									<div className="description-documents">
										<p>{document.description}</p>
									</div>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</section>
	);
};
