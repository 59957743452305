import { useQuery } from '@tanstack/react-query';
import Api from '../../../../api';
import { Category } from '../types';
import { useApi } from '../../hooks/useApi';

const fetchCategories = (api: Api) => async () => {
	const response = await api.fetchCategories();
	return response.data as Category[];
};

export const useCategoriesQuery = () => {
	const api = useApi();
	return useQuery({
		queryKey: ['categories'],
		queryFn: fetchCategories(api),
	});
};
