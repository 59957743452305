import { createContext, useContext } from 'react';
import { Event } from '@invityou/types';
import { useEventQuery } from './useEventQuery';
import { LanguageShort } from '../../../i18n';

const Context = createContext<Event | null>(null);

interface Props {
	eventSlug: string;
	lang?: string;
	children?: React.ReactNode;
}

const EventProvider: React.FC<Props> = ({ eventSlug, lang, children }) => {
	const eventQuery = useEventQuery(
		eventSlug,
		{
			expand: ['logo', 'banner', 'organizer'],
		},
		lang as LanguageShort
	);

	if (eventQuery.isError) {
		throw new Error('useEventQuery failed in EventProvider.');
	}

	return eventQuery.isSuccess ? (
		<Context.Provider value={eventQuery.data}>{children}</Context.Provider>
	) : null;
};

const useEvent = () => {
	const event = useContext(Context);

	if (!event) {
		throw new Error('useEvent must be used within an EventProvider');
	}

	return event;
};

export { EventProvider, useEvent };
