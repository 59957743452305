import { useQuery } from '@tanstack/react-query';
import { MagicForm } from '../magicform/components/MagicForm';
import Alert from '../components/Alert';
import { useProtectedAuth } from '../hooks/useAuth';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import axios from 'axios';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import Header from '../components/layout/Header';

const WAS_REJECTED = 3;

export const RegistrationForm = () => {
	const event = useEvent();
	const { prefix } = useAbsolutePath();
	const { __, lang } = useTranslate();

	useDocTitle(`${__('Registration form')} | ${event.name}`);

	const { isLoggedIn, user, isAdmin } = useProtectedAuth();

	const formQuery = useQuery({
		queryKey: ['form_data', prefix, user?.id, lang],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/registerJson`, {
				headers: lang ? { 'Accept-Language': lang } : undefined,
			});
			return data as
				| {
						error: null;
						formId: string | null;
				  }
				| {
						error: 'EVENT_FULL';
						formId: null;
				  }
				| {
						error: 'REGISTRATION_CLOSED';
						closedRegistrationMessage: string | null;
						formId: string | null;
				  }
				| {
						error: 'COMPANION_FORM_NOT_SET';
						formId: null;
				  }
				| {
						error: 'PAYMENT_INFO_NOT_SET';
						formId: null;
				  };
		},
		enabled: isLoggedIn,
	});

	if (formQuery.isPending) {
		return null;
	}

	const formId = formQuery.data?.formId;

	const maxCompanions = event.params.companions_max;

	const isFormDisabled =
		user.status !== null && event.params.isFreezedRegistration;

	return (
		<>
			{!event.template.header_hide_internal_pages && <Header hideFormButton />}
			<section className="section-long">
				<div className="container section-container">
					{user.status === WAS_REJECTED ? (
						<Alert type="warning">
							{__(
								'We are sorry, your registration has been rejected by the organizers.'
							)}
						</Alert>
					) : formQuery.data?.error === 'REGISTRATION_CLOSED' ? (
						<div
							style={{
								marginTop: '20px',
								border: '1px solid var(--textvariant)',
								borderRadius: '5px',
								padding: '55px 30px 30px',
							}}
						>
							{formQuery.data.closedRegistrationMessage ? (
								<div
									dangerouslySetInnerHTML={{
										__html: formQuery.data.closedRegistrationMessage,
									}}
								></div>
							) : (
								__('We are sorry, registrations are closed.')
							)}
						</div>
					) : formQuery.data?.error === 'COMPANION_FORM_NOT_SET' ? (
						isAdmin ? (
							<Alert type="danger">
								{__('You need to create a companions form.')}
							</Alert>
						) : (
							<Alert type="warning">
								{__('We are sorry, registrations are closed.')}
							</Alert>
						)
					) : formQuery.data?.error === 'PAYMENT_INFO_NOT_SET' ? (
						isAdmin ? (
							<Alert type="danger">
								{__(
									'An organizer need to fill the payment info before any guest can register when ticketing is enabled.'
								)}
							</Alert>
						) : (
							<Alert type="warning">
								{__('We are sorry, registrations are closed.')}
							</Alert>
						)
					) : formQuery.data?.error === 'EVENT_FULL' ? (
						<Alert type="warning">
							{__(
								'We are sorry, the maximum number of registrations has been reached.'
							)}
						</Alert>
					) : !formId ? (
						isAdmin ? (
							<Alert type="danger">{__('Form not set.')}</Alert>
						) : (
							<Alert type="warning">
								{__('We are sorry, registrations are closed.')}
							</Alert>
						)
					) : (
						<div className="questionnaire">
							<MagicForm
								config={{
									formId,
									lang,
									userId: user.id,
									presetValues: {
										'User.mail': user.mail,
									},
									hidePreset: ['User.mail'],
									isFormDisabled,
									maxCompanions,
								}}
							/>
						</div>
					)}
				</div>
			</section>
		</>
	);
};
