import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { useEvent } from '../hooks/useEvent';

interface Config {
	publishableKey: string;
	connectedAccountId: string | null;
	mode: 'test' | 'live';
	couponsEnabled: boolean;
	successUrl: string;
}

const Context = createContext<Config | null>(null);

export const StripeContainer = () => {
	const { prefix } = useAbsolutePath();
	// TODO: replace any with Promise<Stripe> when types of @stripe/stripe-js and @stripe/react-stripe-js match
	const [stripe, setStripe] = useState<any>(null);
	const [config, setConfig] = useState<Config | null>(null);
	const event = useEvent();

	const isTicketingEnabled =
		event.params.ticketing_enabled && event.tickets_on_sale;

	useEffect(() => {
		if (stripe === null && isTicketingEnabled) {
			axios.get(`${prefix}/forms/stripeJson`).then((response) => {
				const data = response.data as Config;

				if (data.connectedAccountId) {
					const stripePromise = loadStripe(data.publishableKey, {
						stripeAccount: data.connectedAccountId,
					});

					setStripe((value: any) => {
						if (!value && stripePromise) {
							console.info('Stripe loaded');
							return stripePromise;
						}

						return null;
					});
					setConfig(data);
				}
			});
		}
	}, [prefix, stripe, isTicketingEnabled]);

	// Redirect when ticketing is not enabled
	const [searchParams] = useSearchParams();
	if (!isTicketingEnabled) {
		const formId = searchParams.get('formId');

		return (
			<Navigate
				to={`${prefix}/register/success${
					formId ? `?formId=${formId}` : ''
				}#start`}
				replace
			/>
		);
	}

	return (
		<>
			{config && stripe && (
				<Elements
					stripe={stripe}
					options={{
						fonts: [
							{
								cssSrc:
									'https://fonts.googleapis.com/css?family=Source+Sans+Pro',
							},
						],
					}}
				>
					<Context.Provider value={config}>
						<Outlet />
					</Context.Provider>
				</Elements>
			)}
		</>
	);
};

export const useTicketingConfig = () => {
	const config = useContext(Context);

	if (!config) {
		throw new Error('useTicketingConfig must be used within StripeContainer');
	}

	return config;
};
