import { createRef, useMemo } from 'react';
import {
	QuestionFromServer,
	OptionFromServer,
	SubmitError,
	Config,
	TranslatedValue,
} from '../types';
import Cleave from 'cleave.js/react';
import { produce } from 'immer';
import { getDefaultLang, translateWithFallback } from '../utils/i18n';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

const showFileName = (url: string) => {
	const urlSplit = url.split('/');
	return urlSplit.pop() || urlSplit.pop();
};

const getValue = (
	question: QuestionFromServer
): QuestionFromServer['answer'] => {
	if (question.input_type_id !== 'checkbox') {
		return question.answer;
	}

	// Checkbox
	const initCheckboxValue: Record<string, boolean> = {};
	for (const option of question.options) {
		if (typeof option.value === 'string') {
			initCheckboxValue[option.value] = false;
		}
	}

	if (question.answer && typeof question.answer === 'object') {
		for (const [key, value] of Object.entries(question.answer)) {
			if (value) {
				initCheckboxValue[key] = true;
			}
		}
	}

	return initCheckboxValue;
};

const randomizeOptions = (options: OptionFromServer[]) => {
	return produce(options, (draft) => {
		for (let i = draft.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = draft[i];
			draft[i] = draft[j];
			draft[j] = temp;
		}
	});
};

const getRemainingQuota = (
	option: OptionFromServer,
	isSelected: boolean
): number => {
	const removeCount = isSelected ? 1 : 0;
	return Math.max(0, option.quota - option.answer_count - removeCount);
};

interface Props {
	question: QuestionFromServer;
	handleMutation: (
		question: QuestionFromServer,
		newAnswer: string | number | Record<string, boolean>
	) => void;
	itemsErrors: Record<string, string>;
	setFileToUpload: (e: File, question: QuestionFromServer) => void;
	submitError: SubmitError | null;
	config: Config;
}

const Question: React.FC<Props> = ({
	question,
	handleMutation,
	itemsErrors,
	setFileToUpload,
	config,
	submitError,
}) => {
	const parentRef: React.RefObject<HTMLInputElement> = createRef();
	const { __ } = useTranslate();
	const value = getValue(question);

	const isAnswerValid = (
		answer: string | number | undefined | Record<string, boolean> | null
	) => {
		if (question.input_type_id === 'file') {
			return question.file ? true : false;
		}

		return answer === undefined ||
			answer === null ||
			answer === '' ||
			(typeof answer === 'object' &&
				Object.keys(answer).every((k) => !answer[k]))
			? false
			: true;
	};

	const getTranslation = (translatedValue: TranslatedValue): string => {
		return translateWithFallback(
			translatedValue,
			config.lang,
			getDefaultLang(translatedValue)
		);
	};

	const parentClass = `form-group 
	${itemsErrors[question.id] ? 'form-error' : ''} 
	${question.required ? 'required' : ''} 
	${
		question.preset === 'User.mail' &&
		submitError &&
		submitError.details &&
		Object.keys(submitError.details)[0] === 'mail'
			? 'form-error'
			: ''
	}`;

	const questionTitle = () => {
		const title = getTranslation(question.title);

		if (
			question.input_type_id === 'radio' ||
			question.input_type_id === 'checkbox'
		) {
			return (
				<legend className="control-label">
					{title}
					{question.regex && question.regex.split('-')[0] === 'limitedMax' && (
						<div style={{ fontSize: 10, fontStyle: 'italic' }}>
							{`${__('limited to')} ${question.regex.split('-')[1]}`}
						</div>
					)}
					{question.regex && question.regex.split('-')[0] === 'limitedMin' && (
						<div style={{ fontSize: 10, fontStyle: 'italic' }}>
							{`${__('minimum')} ${question.regex.split('-')[1]}`}
						</div>
					)}
				</legend>
			);
		}

		if (question.input_type_id === 'section-title') {
			return <h3 className="form-content-title">{title}</h3>;
		}

		return (
			<label className="control-label" htmlFor={question.id}>
				{title}
			</label>
		);
	};

	const helpBlock = (
		<div className="help-block">{getTranslation(question.help)}</div>
	);

	const errorValidation = (questionId: string) => {
		let errorContent: string | null = null;

		const errorType = itemsErrors[questionId];
		switch (errorType) {
			case 'required':
				if (!isAnswerValid(value)) {
					errorContent = __('This field is required.');
				}
				break;
			case 'number':
				errorContent = __('This is not a valid number.');
				break;
			case 'max':
				errorContent = __('This number is too big.');
				break;
			case 'min':
				errorContent = __('This number is too small.');
				break;
			case 'max_file_size':
				errorContent = __(
					'The file you try to upload is too large. The maximum size accepted is 15 MB.'
				);
				break;
			case 'limitedMax':
				errorContent = __('You answered too many choices');
				break;
			case 'limitedMin':
				errorContent = __('You answered too few choices');
				break;
			case 'format_date':
				errorContent = __('Date format is incorrect.');
				break;
			case 'format_text_idcard':
				errorContent = __(
					'Please enter a valid 9-digit french identity card number.'
				);
				break;
			case 'format_text_passport':
				errorContent = __('Please enter a valid 9-digit passport number.');
				break;
			case 'format_text_phone':
				errorContent = __(
					'Please enter a valid phone number. Examples: 04XXXXXXXX / +33XXXXXXXXX'
				);
				break;
			case 'format_mail':
				errorContent = __('Mail format is incorrect.');
				break;
		}

		return (
			errorContent && (
				<div className="help-block text-error">
					<div>
						<span>{errorContent}</span>
					</div>
				</div>
			)
		);
	};

	const handleCheckboxChange = (valueChanged: string, isChecked: boolean) => {
		if (value && typeof value === 'object') {
			const oldVals = structuredClone(value);
			oldVals[valueChanged] = !oldVals[valueChanged];
			handleMutation(question, oldVals);
		}
	};

	const options = useMemo(() => {
		return question.random_options_order
			? randomizeOptions(question.options)
			: question.options;
	}, [question.random_options_order, question.options]);

	if (question.input_type_id === 'section-title') {
		return (
			<>
				{questionTitle()}
				<div
					dangerouslySetInnerHTML={{
						__html: getTranslation(question.help),
					}}
				></div>
			</>
		);
	}

	switch (question.input_type_id) {
		case 'text':
		case 'textarea':
			return (
				<div className={parentClass}>
					{questionTitle()}

					<div className="form-col">
						{question.input_type_id === 'text' &&
							(question.regex === 'identityCard' ||
							question.regex === 'passport' ? (
								<Cleave
									value={typeof value === 'string' ? (value ?? '') : ''}
									type="text"
									className="form-control"
									name={question.id}
									id={question.id}
									placeholder="XXXXXXXXX"
									required={question.required}
									disabled={
										question.disabled ||
										config.isFormDisabled ||
										question.readonly
									}
									onChange={(e) => {
										const value = e.target.value;
										handleMutation(question, value);
									}}
									options={{
										delimiter: '',
										blocks: [9],
										uppercase: true,
									}}
								/>
							) : (
								<input
									value={typeof value === 'string' ? (value ?? '') : ''}
									type="text"
									className="form-control"
									name={question.id}
									id={question.id}
									required={question.required}
									disabled={
										question.disabled ||
										config.isFormDisabled ||
										question.readonly
									}
									onChange={(e) => {
										const value = e.target.value;
										handleMutation(question, value);
									}}
								/>
							))}

						{question.input_type_id === 'textarea' && (
							<textarea
								value={typeof value === 'string' ? (value ?? '') : ''}
								className="form-control"
								name={question.id}
								id={question.id}
								required={question.required}
								disabled={
									question.disabled ||
									config.isFormDisabled ||
									question.readonly
								}
								onChange={(e) => {
									const value = e.target.value;
									handleMutation(question, value);
								}}
							/>
						)}

						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</div>
			);

		case 'number':
			return (
				<div className={parentClass}>
					{questionTitle()}

					<div className="form-col">
						<input
							type="number"
							value={value && typeof value !== 'object' ? value : ''}
							className="form-control"
							name={question.id}
							id={question.id}
							required={question.required}
							min={
								question.regex && question.regex.split('-')[0] === 'minimum'
									? Number.parseInt(question.regex.split('-')[1])
									: undefined
							}
							max={
								question.regex && question.regex.split('-')[0] === 'maximum'
									? Number.parseInt(question.regex.split('-')[1])
									: undefined
							}
							onChange={(e) => {
								const value = e.target.value;
								handleMutation(question, value);
							}}
							disabled={
								question.disabled || config.isFormDisabled || question.readonly
							}
						/>
						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</div>
			);

		case 'radio':
			return (
				<fieldset className={parentClass}>
					{questionTitle()}
					<div className="form-col">
						<div className="radio-group">
							{options.map((option) => (
								<div className="radio" key={option.id}>
									<label htmlFor={`${question.id} - ${option.id}`}>
										<input
											checked={value === option.value}
											type="radio"
											name={question.id}
											id={`${question.id} - ${option.id}`}
											value={option.value ?? ''}
											onChange={(e) => {
												const value = e.target.value;
												handleMutation(question, value);
											}}
											disabled={
												option.filled ||
												question.disabled ||
												config.isFormDisabled ||
												question.readonly
											}
										/>
										{getTranslation(option.title)}
										{question.visible_quotas && option.quota > 0 && (
											<span
												style={{
													marginLeft: 10,
													fontStyle: 'italic',
													fontSize: 14,
												}}
											>
												{`${getRemainingQuota(
													option,
													value === option.value.toString()
												)} ${__('left')}`}
											</span>
										)}
									</label>

									{option.filled && (
										<div className="text-warning">
											{__('Quota is filled for this option.')}
										</div>
									)}
								</div>
							))}
						</div>

						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</fieldset>
			);
		case 'select':
			return (
				<div className={parentClass}>
					{questionTitle()}

					<div className="form-col">
						<select
							className={`form-control ${
								itemsErrors[question.id] &&
								itemsErrors[question.id] == 'required' &&
								!isAnswerValid(value)
									? 'form-error'
									: ''
							} `}
							value={typeof value === 'string' ? value : ''}
							name={question.id}
							id={question.id}
							disabled={
								question.disabled || config.isFormDisabled || question.readonly
							}
							required={question.required}
							onChange={(e) => {
								const value = e.target.value;
								handleMutation(question, value);
							}}
						>
							<option value="">{__('Select an answer')}</option>

							{question.options.map((option) => {
								return (
									<option
										key={option.id}
										value={option.value ?? ''}
										disabled={
											option.filled ||
											question.disabled ||
											config.isFormDisabled
										}
									>
										{getTranslation(option.title)}

										{question.visible_quotas &&
											option.quota > 0 &&
											` - ${getRemainingQuota(
												option,
												value === option.value.toString()
											)} ${__('left')}`}
									</option>
								);
							})}
						</select>
						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</div>
			);

		case 'checkbox':
			return (
				<fieldset className={parentClass}>
					{questionTitle()}

					<div className="form-col">
						<div className="checkbox-group">
							{options.map((option) => (
								<div
									key={option.id}
									className={`checkbox ${
										option.filled || question.disabled
											? 'disabled text-muted'
											: ''
									}`}
								>
									<label htmlFor={`${question.id} - ${option.id}`}>
										<input
											checked={
												typeof value === 'object' && option.value && value
													? value[option.value]
														? true
														: false
													: false
											}
											type="checkbox"
											name={question.id}
											id={`${question.id} - ${option.id}`}
											value={option.value ?? ''}
											disabled={
												option.filled ||
												question.disabled ||
												config.isFormDisabled ||
												question.readonly
											}
											onChange={(e) =>
												handleCheckboxChange(e.target.value, e.target.checked)
											}
										/>
										{getTranslation(option.title)}

										{question.visible_quotas && option.quota > 0 && (
											<span
												style={{
													marginLeft: 10,
													fontStyle: 'italic',
													fontSize: 14,
												}}
											>
												{`${getRemainingQuota(
													option,
													typeof value === 'object' &&
														value !== null &&
														value[option.value]
												)}  ${__('left')}`}
											</span>
										)}
									</label>
									{option.filled && (
										<div className="text-warning">
											{__('Quota is filled for this option.')}
										</div>
									)}
								</div>
							))}
						</div>
						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</fieldset>
			);

		case 'date':
			return (
				<div className={parentClass}>
					{questionTitle()}

					<div className="form-col datepicker">
						<Cleave
							value={typeof value === 'string' ? (value ?? '') : ''}
							placeholder={__('dd/mm/yyyy')}
							options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
							className="form-control"
							name={question.id}
							id={question.id}
							required={question.required}
							disabled={
								question.disabled || config.isFormDisabled || question.readonly
							}
							onChange={(e) => {
								const value = e.target.value;
								handleMutation(question, value);
							}}
						/>
						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</div>
			);

		case 'time':
			return (
				<div className={parentClass}>
					{questionTitle()}

					<div className="form-col timepicker">
						<Cleave
							value={typeof value === 'string' ? (value ?? '') : ''}
							placeholder="--:--"
							options={{ time: true, timePattern: ['h', 'm'] }}
							className="form-control"
							name={question.id}
							id={question.id}
							required={question.required}
							disabled={
								question.disabled || config.isFormDisabled || question.readonly
							}
							onChange={(e) => {
								const value = e.target.value;
								handleMutation(question, value);
							}}
						/>
						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</div>
			);

		case 'file':
			return (
				<div className={parentClass}>
					{questionTitle()}

					<div className="form-col filepicker">
						<div className="form-control">
							<label htmlFor={question.id}>
								<input
									type="file"
									id={question.id}
									name={question.id}
									ref={parentRef}
									onChange={(e) => {
										const files = e.target.files;
										if (files) {
											setFileToUpload(files[0], question);
										}
									}}
									required={question.required}
									className={itemsErrors[question.id] ? 'form-error' : ''}
									disabled={
										question.disabled ||
										config.isFormDisabled ||
										question.readonly
									}
								/>

								{question.file ? (
									question.file.name
								) : value && typeof value === 'string' ? (
									showFileName(value)
								) : (
									<span>{__('Upload a file')}</span>
								)}
							</label>
						</div>
						{errorValidation(question.id)}
						{helpBlock}
					</div>
				</div>
			);
	}

	return null;
};

export default Question;
