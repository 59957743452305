import { parseISO } from 'date-fns';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';

const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

interface PrettyDateProps {
	start: string | null;
	end: string | null;
}

const PrettyDate = ({ start, end }: PrettyDateProps) => {
	const { __, lang } = useTranslate();

	if (start && end) {
		const startDate = parseISO(start);
		const endDate = parseISO(end);

		const isSameDay =
			startDate.getFullYear() === endDate.getFullYear() &&
			startDate.getMonth() === endDate.getMonth() &&
			startDate.getDate() === endDate.getDate();

		if (isSameDay) {
			return (
				<span>
					{__(
						'%s from %s to %s',
						capitalize(
							startDate.toLocaleString(lang, {
								day: 'numeric',
								weekday: 'long',
								month: 'long',
								year: 'numeric',
							})
						),
						startDate.toLocaleString(lang, {
							hour: 'numeric',
							minute: 'numeric',
						}),
						endDate.toLocaleString(lang, {
							hour: 'numeric',
							minute: 'numeric',
						})
					)}
				</span>
			);
		}

		return (
			<span>
				{__(
					'From %s at %s',
					startDate.toLocaleString(lang, {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					}),
					startDate.toLocaleString(lang, {
						hour: 'numeric',
						minute: 'numeric',
					})
				)}

				<br />
				{__(
					'To %s at %s',
					endDate.toLocaleString(lang, {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					}),
					endDate.toLocaleString(lang, {
						hour: 'numeric',
						minute: 'numeric',
					})
				)}
			</span>
		);
	}

	if (start) {
		const startDate = parseISO(start);

		const first = startDate.toLocaleString(lang, {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		});

		return (
			<span>
				{__(
					'%s at %s',
					capitalize(first),
					startDate.toLocaleString(lang, {
						hour: 'numeric',
						minute: 'numeric',
					})
				)}
				<br />
			</span>
		);
	}

	return null;
};

export const DetailsBlock = () => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const event = useEvent();

	const eventHasDate = event.start_date !== null && event.start_date !== '';
	const eventHasLocation = event.address_name || event.address || event.city;

	if (!eventHasDate && !eventHasLocation) {
		return null;
	}

	return (
		<section id="details">
			<div className="container section-container custom-font-content">
				<ul>
					{eventHasDate && (
						<li>
							<span className="icon">
								<i className="fa fa-calendar" aria-hidden="true"></i>
							</span>
							<span className="content">
								<PrettyDate start={event.start_date} end={event.end_date} />
							</span>
						</li>
					)}

					{eventHasLocation && (
						<li>
							<span className="icon">
								<i className="fa fa-map-marker" aria-hidden="true"></i>
							</span>
							<span className="content">
								{event.address_name && (
									<>
										{event.address_name} <br />
									</>
								)}
								{event.address && (
									<>
										{event.address} <br />
									</>
								)}
								{event.zip} {event.city}
								{event.country && <> - {event.country}</>}
							</span>
						</li>
					)}
				</ul>

				{eventHasDate && event.calendar_link_enabled && (
					<div className="add-calendar text-center">
						<a
							href={`${prefix}/events/calendar`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{__('Add to my calendar')}
						</a>
					</div>
				)}
			</div>
		</section>
	);
};
