import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { getCakeLang } from '../../../../i18n';
import { Config, TermsChecked } from '../types';

interface Props {
	areTermsChecked: TermsChecked;
	checkTerms: (areTermsCheckedValue: TermsChecked) => void;
	termsUrl: string | null;
	itemsErrors: Record<string, string>;
	config: Config;
}

const TermsAndConditions: React.FC<Props> = ({
	areTermsChecked,
	checkTerms,
	itemsErrors,
	termsUrl,
	config,
}) => {
	const { __ } = useTranslate();
	const handleTermsCheck = () => {
		checkTerms({
			...areTermsChecked,
			terms: !areTermsChecked.terms,
		});
	};

	const handlePrivacyCheck = () => {
		checkTerms({
			...areTermsChecked,
			privacy: !areTermsChecked.privacy,
		});
	};

	return (
		<div
			className={`form-group form-group-row ${
				itemsErrors['terms'] === 'required' &&
				(!areTermsChecked.privacy ||
					(termsUrl && termsUrl !== '' && !areTermsChecked.terms))
					? 'form-error'
					: ''
			}`}
		>
			<div className="checkbox-group">
				<div className="checkbox required">
					<label htmlFor="privacy-checkbox">
						<input
							type="checkbox"
							id="privacy-checkbox"
							value="1"
							checked={areTermsChecked.privacy}
							required={true}
							disabled={config.isFormDisabled}
							onChange={handlePrivacyCheck}
						/>
						{__('By checking this case, I acknowledge having read the')}
						{` `}
						<a
							href={`/files/legal/pp_${getCakeLang(config.lang)}.pdf`}
							target="_blank"
							rel="noreferrer"
							title={__('Privacy Policy')}
						>
							{__('Privacy Policy')}
						</a>
						.
					</label>
				</div>

				{termsUrl && termsUrl !== '' && (
					<div className="checkbox required">
						<label htmlFor="terms-checkbox">
							<input
								type="checkbox"
								id="terms-checkbox"
								value="1"
								required={true}
								checked={areTermsChecked.terms}
								disabled={config.isFormDisabled}
								onChange={handleTermsCheck}
							/>
							{__(
								'Click here to indicate that you have read and agree to the terms presented in the'
							)}
							{` `}
							<a
								href={termsUrl}
								target="_blank"
								rel="noreferrer"
								title=" {__('Terms and Conditions agreement')"
							>
								{__('Terms and Conditions agreement')}
							</a>
						</label>
					</div>
				)}
			</div>

			{itemsErrors['terms'] === 'required' &&
				(!areTermsChecked.privacy ||
					(termsUrl && termsUrl !== '' && !areTermsChecked.terms)) && (
					<div className="help-block text-error">
						{__('Please indicate that you have read and agree to the')}
						{` `}

						{!areTermsChecked.privacy && <span> {__('Privacy Policy')}</span>}

						{!areTermsChecked.privacy &&
							!areTermsChecked.terms &&
							termsUrl !== '' &&
							termsUrl && (
								<span>
									{` `}
									{__('and')}
									{` `}
								</span>
							)}

						{!areTermsChecked.terms && termsUrl && termsUrl !== '' && (
							<span>{__('Terms and Conditions agreement')}</span>
						)}
					</div>
				)}
		</div>
	);
};

export default TermsAndConditions;
