import { Link } from 'react-router-dom';
import PaymentForm, {
	canSubmit,
	PaymentFormData,
} from '../ticketing/components/PaymentForm';
import PaymentMethodSelector from '../ticketing/components/PaymentMethodSelector';
import ExternalPaymentCard, {
	ExternalPayementFormData,
} from '../ticketing/components/ExternalPaymentCard';
import { useParamsQuery } from '../ticketing/store/params';
import { useUserQuery } from '../ticketing/store/user';
import {
	useAwaitOrderMutation,
	useOrderQuery,
	usePayOrderMutation,
} from '../ticketing/store/order';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useAuth } from '../hooks/useAuth';
import { useTicketingConfig } from '../ticketing/StripeContainer';
import { useAbsolutePath } from '../hooks/useAbsolutePath';

const PENDING_APPROVAL = 2;
const IS_EXPECTED = 4;

export const TicketingPayment = () => {
	const event = useEvent();
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();

	useDocTitle(event.name);

	const { user: authUser } = useAuth();

	if (!authUser) {
		throw new Error('User not found.');
	}

	const config = useTicketingConfig();
	const mode = config.mode;
	const userId = authUser.id;
	const successUrl = config.successUrl;

	const redirectToSuccessPage = () => {
		window.location.href = successUrl;
	};

	const paramsQuery = useParamsQuery();
	const userQuery = useUserQuery(userId);
	const orderQuery = useOrderQuery(userId, mode);
	const payOrderMutation = usePayOrderMutation();
	const awaitOrderMutation = useAwaitOrderMutation();
	const processing = awaitOrderMutation.isPending || payOrderMutation.isPending;
	const paymentError =
		(awaitOrderMutation.error as Error | null) ||
		(payOrderMutation.error as Error | null);

	const handleSubmit = ({
		terms,
		name,
		address,
		address_zip,
		city,
		siret,
	}: PaymentFormData) => {
		if (
			!canSubmit(processing, { terms, name, address, zip: address_zip, city })
		) {
			return;
		}

		payOrderMutation.mutate(
			{
				name: name,
				address: address,
				zip: address_zip,
				city: city,
				siret: siret,
			},
			{
				onSuccess: () => {
					redirectToSuccessPage();
				},
			}
		);
	};

	const handleExternalPaymentSubmit = ({
		terms,
		name,
		address,
		zip,
		city,
		siret,
	}: ExternalPayementFormData) => {
		if (!canSubmit(processing, { terms, name, address, zip, city })) {
			return;
		}

		awaitOrderMutation.mutate(
			{
				formValues: {
					name,
					address,
					zip,
					city,
					siret,
				},
			},
			{
				onSuccess: () => {
					redirectToSuccessPage();
				},
			}
		);
	};

	const user = userQuery.data?.user;
	const params = paramsQuery.data;
	const loaded =
		userQuery.isFetched && orderQuery.isFetched && paramsQuery.isFetched;

	return (
		<section id="buy">
			<div className="section-container">
				<div className="ticketing">
					<div className="container">
						<div className="panel panel-default">
							<div className="panel-body" style={{ padding: '30px' }}>
								{!loaded ? (
									<div style={{ textAlign: 'center' }}>{__('Loading...')}</div>
								) : (
									<div className="row">
										<div className="col-md-8 col-md-offset-2">
											<PaymentMethodSelector
												forceCreditCard={
													!params || !params.allow_other_payment_methods
												}
												creditCardMethod={
													<PaymentForm
														mode={mode}
														onSubmit={handleSubmit}
														testMode={mode === 'test'}
														paymentInProgress={processing}
														user={user}
													/>
												}
												externalMethod={
													<ExternalPaymentCard
														onSubmit={(formValues: ExternalPayementFormData) =>
															handleExternalPaymentSubmit(formValues)
														}
														content={
															params
																? params.other_payment_method_information
																: ''
														}
														processing={processing}
														user={user}
													/>
												}
											/>

											{paymentError && (
												<div
													className="alert alert-danger"
													style={{ marginTop: '25px' }}
													role="alert"
												>
													{paymentError.message}
												</div>
											)}
										</div>
									</div>
								)}

								<hr className="large" />

								<div>
									<Link to={`${prefix}/ticketing`} className="btn btn-default">
										{__('Previous')}
									</Link>

									{user &&
										(user.status === IS_EXPECTED ||
											user.status === PENDING_APPROVAL) && (
											<a
												href={successUrl}
												className="btn btn-secondary btn-ticketing-1 pull-right"
											>
												{__('Skip this step')}
											</a>
										)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
