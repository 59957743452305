import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import {
	RouteObject,
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';
import Layout from './Layout';
import { Show } from './pages/Show';
import { Legals } from './pages/Legals';
import { Contact } from './pages/Contact';
import { Participants } from './pages/Participants';
import { Documents } from './pages/Documents';
import { Photos } from './pages/Photos';
import { MemberArea } from './pages/MemberArea';
import { SharedFiles } from './pages/SharedFiles';
import { GodsonsAndRecommendations } from './pages/GodsonsAndRecommendations';
import { AddOrEditGodson } from './pages/AddOrEditGodson';
import { AddRecommendations } from './pages/AddRecommendation';
import { RegistrationForm } from './pages/RegistrationForm';
import { SurveyForm } from './pages/SurveyForm';
import { initSentry } from '../../sentry';
import { TicketingIndex } from './pages/TicketingIndex';
import { StripeContainer } from './ticketing/StripeContainer';
import { TicketingPayment } from './pages/TicketingPayment';
import { ProtectedRoute } from './hooks/useAuth';
import { RegistrationComplete } from './pages/RegistrationComplete';
import { SurveyComplete } from './pages/SurveyComplete';
import { UserOrders } from './pages/UserOrders';
import { UserGodsons } from './pages/UserGodsons';
import { Login } from './pages/Login';
import { SendingToken } from './pages/SendingToken';
import { CheckinsIndex } from './pages/CheckinsIndex';
import { OnlineCheckin } from './pages/OnlineCheckin';
import { CancelRegistration } from './pages/CancelRegistration';
import { CustomPage } from './pages/CustomPage';

initSentry();

const reactMount = document.querySelector('#react-mount');

if (!reactMount) {
	throw new Error('React mount node not found.');
}

const root = createRoot(reactMount);

const perEventRoutes: RouteObject[] = [
	{ index: true, element: <Show /> },
	{ path: 'documents', element: <Documents /> },
	{
		path: 'photos',
		element: <Photos />,
	},
	{ path: 'participants', element: <Participants /> },
	{ path: 'contact', element: <Contact /> },
	{ path: 'legals', element: <Legals /> },
	{ path: 'login', element: <Login /> },
	{ path: 'signup', element: <Login /> },
	{ path: 'sending-token', element: <SendingToken /> },
	{ path: 'checkins', element: <CheckinsIndex /> },
	{ path: 'checkins/:id', element: <OnlineCheckin /> },
	{ path: 'pages/:shortid/:slug', element: <CustomPage /> },
	{
		path: 'member',
		element: (
			<ProtectedRoute>
				<MemberArea />
			</ProtectedRoute>
		),
	},
	{
		path: 'member/orders',
		element: (
			<ProtectedRoute>
				<UserOrders />
			</ProtectedRoute>
		),
	},
	{
		path: 'member/companions',
		element: (
			<ProtectedRoute>
				<UserGodsons />
			</ProtectedRoute>
		),
	},
	{
		path: 'member/cancel_registration',
		element: (
			<ProtectedRoute>
				<CancelRegistration />
			</ProtectedRoute>
		),
	},
	{
		path: 'sharedfiles',
		element: (
			<ProtectedRoute>
				<SharedFiles />
			</ProtectedRoute>
		),
	},
	{
		path: 'register',
		element: (
			<ProtectedRoute>
				<RegistrationForm />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other',
		element: (
			<ProtectedRoute>
				<GodsonsAndRecommendations />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other/godsons',
		element: (
			<ProtectedRoute>
				<AddOrEditGodson />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other/godsons/:id',
		element: (
			<ProtectedRoute>
				<AddOrEditGodson />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other/recommendation',
		element: (
			<ProtectedRoute>
				<AddRecommendations />
			</ProtectedRoute>
		),
	},
	{
		path: 'ticketing',
		element: <StripeContainer />,
		children: [
			{
				index: true,
				element: (
					<ProtectedRoute>
						<TicketingIndex />
					</ProtectedRoute>
				),
			},
			{
				path: 'payment',
				element: (
					<ProtectedRoute>
						<TicketingPayment />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: 'register/success',
		element: (
			<ProtectedRoute>
				<RegistrationComplete />
			</ProtectedRoute>
		),
	},
	{
		path: 'survey',
		element: (
			<ProtectedRoute>
				<SurveyForm />
			</ProtectedRoute>
		),
	},
	{
		path: 'survey/success',
		element: (
			<ProtectedRoute>
				<SurveyComplete />
			</ProtectedRoute>
		),
	},
];

const router = createBrowserRouter([
	{
		path: '/:eventSlug',
		element: <Layout />,
		children: perEventRoutes,
	},
	{
		path: '/:lang/:eventSlug',
		element: <Layout />,
		children: perEventRoutes,
	},
]);

const queryClient = new QueryClient();

root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider
				router={router}
				future={{
					v7_startTransition: true,
				}}
			/>
		</QueryClientProvider>
	</StrictMode>
);
