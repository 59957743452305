import { Link, useSearchParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../hooks/useAbsolutePath';

export const SendingToken = () => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { isLoggedIn } = useAuth();
	const [searchParams] = useSearchParams();

	useDocTitle(__('Login mail'));

	const mail = searchParams.get('mail') ?? '';

	return (
		<section>
			<div className="container section-container">
				{isLoggedIn ? (
					<p style={{ textAlign: 'center' }}>
						{__('You are already logged in.')}{' '}
						<Link to={prefix}>{__('Go back to the homepage')}</Link>
					</p>
				) : (
					<>
						<div style={{ textAlign: 'center', marginBottom: 25 }}>
							<img src="/img/paperplane.svg" width="250" />
						</div>

						<h2 style={{ textAlign: 'center', color: 'var(--titlevariant)' }}>
							{__('Check your emails !')}
						</h2>

						<p>
							{__('If you are invited, you will receive an email to')}{' '}
							<strong>{mail}</strong>.
							<br />
							{__(
								'Click on the link inside the mail to confirm your address and fill the registration form.'
							)}
						</p>

						<p>
							{__('Wrong email address ?')}{' '}
							<Link to={`${prefix}/login`}>{__('Try again.')}</Link>
						</p>
					</>
				)}
			</div>
		</section>
	);
};
