interface Props {
	code: string;
	removeCoupon: (code: string) => void;
}

const CouponItem: React.FC<Props> = ({ code, removeCoupon }) => (
	<li className="custom-title-color custom-title-border-color">
		{code}{' '}
		<a role="button" onClick={() => removeCoupon(code)}>
			<i className="fa fa-times-circle" aria-hidden="true" />
		</a>
	</li>
);

export default CouponItem;
