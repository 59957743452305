import { Link } from 'react-router-dom';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { useProtectedAuth } from '../hooks/useAuth';
import { SubmitButton } from '../components/SubmitButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../hooks/useApi';
import Alert from '../components/Alert';
import { useState } from 'react';

const HAS_CANCELLED = 6;

export const CancelRegistration = () => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { user } = useProtectedAuth();
	const api = useApi();

	useDocTitle(__('Cancel my registration'));

	const [isChecked, setIsChecked] = useState(false);

	const queryClient = useQueryClient();
	const cancelRegistrationMutation = useMutation({
		mutationFn: async () => {
			await api.updateUser(user.id, { status: HAS_CANCELLED });
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['event_auth'] });
		},
	});

	return (
		<section>
			<div className="container section-container">
				{cancelRegistrationMutation.isSuccess && (
					<Alert type="success">
						{__('You have been unregistered from this event.')}
					</Alert>
				)}

				{user.status === HAS_CANCELLED ? (
					<div>{__('You are not registered to this event.')}</div>
				) : (
					<div className="form">
						<h2 className="form-title">
							<ol className="breadcrumb">
								<li>
									<Link to={`${prefix}/member#start`}>
										{user.firstname || user.lastname
											? `${user.firstname} ${user.lastname}`
											: user.mail}
									</Link>
								</li>
								<li>{__('Cancel my registration')}</li>
							</ol>
						</h2>

						<form
							onSubmit={(e) => {
								e.preventDefault();
							}}
						>
							<div>
								{__('Do you really want to unregister ?')}
								<div className="checkbox-group" style={{ marginBottom: 25 }}>
									<div className="checkbox required">
										<label>
											<input
												type="checkbox"
												name="confirm"
												required
												checked={isChecked}
												onChange={(e) => {
													setIsChecked(e.target.checked);
												}}
											/>
											{__(
												'By checking this case, I acknowledge wanting to get unregistered from this event'
											)}
										</label>
									</div>
									<div className="checkbox required"></div>
								</div>
							</div>

							<SubmitButton
								disabled={!isChecked}
								loading={cancelRegistrationMutation.isPending}
								onClick={() => {
									cancelRegistrationMutation.mutate();
								}}
							>
								{__('Unregister')}
							</SubmitButton>
						</form>
					</div>
				)}
			</div>
		</section>
	);
};
