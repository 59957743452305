import { useQuery } from '@tanstack/react-query';
import { Config, FormFromServer } from '../types';
import { useApi } from '../../hooks/useApi';

export const QUERY_KEY = 'forms';

export const useFormQuery = ({ formId, userId }: Config) => {
	const api = useApi();

	return useQuery({
		queryKey: [QUERY_KEY, formId, userId],
		queryFn: async () => {
			const response = await api.fetchFormItems(formId, userId);
			return response.data as FormFromServer;
		},
		refetchOnWindowFocus: false,
		retry: false,
	});
};
