import { Photo } from '@invityou/types';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { Link } from 'react-router-dom';

interface Props {
	photos: Photo[];
}

export const GalleryBlock = ({ photos }: Props) => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const event = useEvent();

	return (
		<section
			id="event-photos"
			className={`${event.template.photos_block ? '' : 'hide'}`}
		>
			<div className="container section-container">
				<h2
					id="event-photos-heading"
					className={`section-heading custom-font-title ${
						event.template.photos_title === null ||
						event.template.photos_title === ''
							? 'hide'
							: ''
					}`}
				>
					{event.template.photos_title}
				</h2>

				<ul className="gallery custom-font-content">
					{photos.map((photo) => {
						return (
							<li key={photo.id}>
								<img src={photo.url} className="fo_gallery"></img>
							</li>
						);
					})}
				</ul>

				<div className="all-elements">
					<Link to={`${prefix}/photos`} className="btn btn-secondary btn-lg">
						{__('All photos')}
					</Link>
				</div>
			</div>
		</section>
	);
};
