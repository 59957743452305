import { useCustomQueryParams } from '../../../shared/hooks/useCustomQueryParams';

export const useLimitQueryParams = (defaultLimit = 50) => {
	return useCustomQueryParams(
		'limit',
		defaultLimit.toString(),
		(input) => Number.parseInt(input, 10),
		(input) => input.toString()
	);
};
