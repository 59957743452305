import {
	useState,
	useEffect,
	createContext,
	useCallback,
	createRef,
	useContext,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface Toast {
	id: string;
	content: string | React.ReactElement;
	type: ToastType;
	options: Options;
}

type ToastType = 'success' | 'info' | 'warning' | 'danger';

interface Options {
	autoClose: boolean;
}

export interface ToastFn {
	(
		content: string | React.ReactElement,
		type: ToastType,
		options?: Partial<Options>
	): string;
	dismiss(): void;
}

interface ToastContainerProps {
	autoClose?: boolean;
	maxToasts?: number;
	children?: React.ReactNode;
}

const fakeToastFn = () => '';
fakeToastFn.dismiss = () => {};

const ToastContext = createContext<ToastFn>(fakeToastFn);

const generateToastId = () => {
	return (Math.random().toString(36) + Date.now().toString(36)).slice(2, 12);
};

const getTypeClass = (type: ToastType): string => {
	switch (type) {
		case 'success':
			return 'alert-success';
		case 'info':
			return 'alert-info';
		case 'warning':
			return 'alert-warning';
		case 'danger':
			return 'alert-danger';
	}
};

const autoCloseDurationMs = 3000;
const transtionDurationMs = 250;

const Toast: React.FC<{
	toast: Toast;
	onRequestClose: (id: string) => void;
	nodeRef: React.RefObject<HTMLDivElement>;
}> = ({ toast, onRequestClose, nodeRef }) => {
	useEffect(() => {
		if (!toast.options.autoClose) {
			return;
		}

		const timer = setTimeout(() => {
			onRequestClose(toast.id);
		}, autoCloseDurationMs);

		return () => {
			clearTimeout(timer);
		};
	}, [toast.options.autoClose, onRequestClose, toast.id]);

	return (
		<div
			ref={nodeRef}
			className={`alert alert-dismissable ${getTypeClass(toast.type)}`}
		>
			<button
				type="button"
				className="close"
				onClick={() => {
					onRequestClose(toast.id);
				}}
			>
				×
			</button>
			{toast.content}
		</div>
	);
};

const ToastContainer: React.FC<ToastContainerProps> = ({
	children,
	autoClose = true,
	maxToasts = 5,
}) => {
	const [toasts, setToasts] = useState<Toast[]>([]);

	// Remove danger toasts when changing page
	const location = useLocation();
	useEffect(() => {
		setToasts((toasts) => toasts.filter((toast) => toast.type !== 'danger'));
	}, [location]);

	const onRequestClose = useCallback((id: string) => {
		setToasts((toasts) => {
			const index = toasts.findIndex((toast) => toast.id === id);
			return [...toasts.slice(0, index), ...toasts.slice(index + 1)];
		});
	}, []);

	const toast: ToastFn = (content, type, options = {}) => {
		const id = generateToastId();
		const optionsGiven = {
			autoClose: type === 'danger' ? false : autoClose,
			...options,
		};

		const toast: Toast = {
			id,
			content,
			type,
			options: optionsGiven,
		};

		setToasts((toasts) => {
			return [...toasts, toast].slice(-maxToasts);
		});

		return id;
	};
	toast.dismiss = () => {
		const currentToastIds = new Set(toasts.map((toast) => toast.id));
		setTimeout(() => {
			setToasts((toasts) => {
				return toasts.filter((toast) => !currentToastIds.has(toast.id));
			});
		}, transtionDurationMs);
	};

	return (
		<ToastContext.Provider value={toast}>
			{createPortal(
				<div className="alert-container">
					<TransitionGroup appear>
						{toasts.reverse().map((toast) => {
							const nodeRef = createRef<HTMLDivElement>();
							return (
								<CSSTransition
									key={toast.id}
									nodeRef={nodeRef}
									classNames="toast"
									timeout={transtionDurationMs}
									unmountOnExit
								>
									<Toast
										toast={toast}
										onRequestClose={onRequestClose}
										nodeRef={nodeRef}
									/>
								</CSSTransition>
							);
						})}
					</TransitionGroup>
				</div>,
				document.body
			)}

			{children}
		</ToastContext.Provider>
	);
};

const useToast = () => useContext(ToastContext);

export { ToastContainer, useToast };
