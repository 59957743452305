import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useEvent } from '../hooks/useEvent';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import UserCard from '../ticketing/components/UserCard';
import OrderSummary from '../ticketing/components/OrderSummary';
import CouponForm, { CouponFormData } from '../ticketing/components/CouponForm';
import CouponItem from '../ticketing/components/CouponItem';
import { useCategoriesQuery } from '../ticketing/store/category';
import { useTicketsQuery } from '../ticketing/store/ticket';
import { useUserQuery } from '../ticketing/store/user';
import {
	useAddCouponMutation,
	useAddTicketMutation,
	useOrderQuery,
	useRemoveCouponMutation,
	useRemoveTicketMutation,
} from '../ticketing/store/order';
import { useProtectedAuth } from '../hooks/useAuth';
import { useTicketingConfig } from '../ticketing/StripeContainer';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { Link } from 'react-router-dom';

export const TicketingIndex = () => {
	const event = useEvent();
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();

	useDocTitle(event.name);

	const { user: authUser } = useProtectedAuth();

	const userId = authUser.id;

	const config = useTicketingConfig();
	const mode = config.mode;
	const couponsEnabled = config.couponsEnabled;

	const categoriesQuery = useCategoriesQuery();
	const ticketsQuery = useTicketsQuery();
	const userQuery = useUserQuery(userId);
	const orderQuery = useOrderQuery(userId, mode);
	const addTicketMutation = useAddTicketMutation();
	const removeTicketMutation = useRemoveTicketMutation();
	const addCouponMutation = useAddCouponMutation();
	const removeCouponMutation = useRemoveCouponMutation();

	const order = orderQuery.data;
	const tickets = ticketsQuery.data ?? [];
	const ticketError =
		(addTicketMutation.error as Error | null) ||
		(removeTicketMutation.error as Error | null);
	const user = userQuery.data?.user;
	const companions = userQuery.data?.companions ?? [];
	const couponError =
		(addCouponMutation.error as Error | null) ||
		(removeCouponMutation.error as Error | null);
	const categories = categoriesQuery.data ?? [];
	const loaded =
		categoriesQuery.isFetched && ticketsQuery.isFetched && userQuery.isFetched;
	const isOrderMutating =
		addTicketMutation.isPending ||
		removeTicketMutation.isPending ||
		addCouponMutation.isPending ||
		removeCouponMutation.isPending;

	const users = user ? [user, ...companions] : [];

	const handleCouponSubmit = (values: CouponFormData) => {
		// Only update order if coupon is not empty
		if (values.coupon) {
			addCouponMutation.mutate(values.coupon);
		}
	};

	return (
		<section id="buy">
			<div className="section-container">
				<div className="ticketing">
					<div className="container-fluid">
						<div className="col-md-10 col-md-offset-1 panel panel-default">
							<div className="panel-body" style={{ padding: '30px' }}>
								<h2 style={{ textAlign: 'center' }}>{__('Your order')}</h2>
								<div style={{ textAlign: 'center' }}>
									{__('Click on a ticket to add it to your basket')}
								</div>
								<hr className="large" />

								{!loaded ? (
									<div style={{ textAlign: 'center' }}>{__('Loading...')}</div>
								) : (
									<div className="row" style={{ minHeight: 700 }}>
										<div className="col-md-8">
											{order &&
												users.map((user, index) => (
													<UserCard
														user={user}
														key={user.id}
														companionIndex={index}
														categories={categories}
														tickets={tickets}
														ticketError={ticketError}
														order={order}
														addTicket={(ticketId: string, userId: string) => {
															if (isOrderMutating) {
																return;
															}

															addTicketMutation.mutate({
																ticket: ticketId,
																user: userId,
															});
														}}
														removeTicket={(
															ticketId: string,
															userId: string
														) => {
															if (isOrderMutating) {
																return;
															}

															removeTicketMutation.mutate({
																ticket: ticketId,
																user: userId,
															});
														}}
													/>
												))}
										</div>
										<div className="col-md-4" style={{ paddingTop: 30 }}>
											{order && (
												<OrderSummary
													tickets={tickets}
													order={order}
													orderQuery={orderQuery}
													isOrderMutating={isOrderMutating}
													users={users}
												>
													<div className="coupons-form">
														<ul className="coupons-list">
															{order.coupons.map((code: string) => (
																<CouponItem
																	key={code}
																	code={code}
																	removeCoupon={() => {
																		if (isOrderMutating) {
																			return;
																		}

																		removeCouponMutation.mutate(code);
																	}}
																/>
															))}
														</ul>

														{couponsEnabled && (
															<CouponForm onSubmit={handleCouponSubmit} />
														)}
													</div>

													{couponError && (
														<div className="alert alert-danger" role="alert">
															{couponError.message}
														</div>
													)}
													{ticketError && (
														<div className="alert alert-danger" role="alert">
															{ticketError.message}
														</div>
													)}
												</OrderSummary>
											)}
										</div>
									</div>
								)}

								<hr className="large" />

								<div>
									<Link to={`${prefix}/register`} className="btn btn-default">
										{__('Back to questionnaire')}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
