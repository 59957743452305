import { Image, Speaker } from '@invityou/types';
import { useEvent } from '../hooks/useEvent';
import { useState } from 'react';
import { useTranslate } from '../../../shared/hooks/useTranslate';

const truncateWithEllipses = (text: string, max: number) => {
	return text.slice(0, Math.max(0, max - 1)) + (text.length > max ? '…' : '');
};

interface SpeakerCardProps {
	speaker: Speaker;
}

const SpeakerCard = ({ speaker }: SpeakerCardProps) => {
	const { __ } = useTranslate();
	const avatar = speaker.avatar as Image | null;

	const maxLength = 300;

	const [showFullDescription, setShowFullDescription] = useState(false);

	return (
		<div className="element-speakers">
			{avatar ? (
				<img src={avatar.url} className="fo_speaker" />
			) : (
				<img src="/img/unknown_big.png" />
			)}

			<div className="about">
				<h5 className="name custom-font-subtitle">
					{speaker.firstname} {speaker.lastname}
				</h5>
				{(speaker.job || speaker.company) && (
					<p className="job">
						{speaker.job}
						{speaker.job && speaker.company && <> - </>}
						{speaker.company}
					</p>
				)}

				{speaker.description && (
					<p className="speakers-description">
						{speaker.description.length < maxLength ? (
							speaker.description
						) : (
							<>
								{showFullDescription
									? speaker.description
									: truncateWithEllipses(speaker.description, maxLength)}

								<a
									role="button"
									className="expand"
									style={{ display: 'block' }}
									onClick={() => {
										setShowFullDescription((state) => !state);
									}}
								>
									{!showFullDescription ? __('See more') : __('See less')}
								</a>
							</>
						)}
					</p>
				)}

				{(speaker.twitter || speaker.linkedin || speaker.facebook) && (
					<ul className="social-speakers">
						{speaker.twitter && (
							<li>
								<a
									href={speaker.twitter}
									target="_blank"
									rel="noopener noreferrer"
									className="twitter btn btn-secondary btn-circle"
								>
									<i className="fa fa-twitter" aria-hidden="true"></i>
								</a>
							</li>
						)}

						{speaker.linkedin && (
							<li>
								<a
									href={speaker.linkedin}
									target="_blank"
									rel="noopener noreferrer"
									className="linkedin btn btn-secondary btn-circle"
								>
									<i className="fa fa-linkedin" aria-hidden="true"></i>
								</a>
							</li>
						)}

						{speaker.facebook && (
							<li>
								<a
									href={speaker.facebook}
									target="_blank"
									rel="noopener noreferrer"
									className="facebook btn btn-secondary btn-circle"
								>
									<i className="fa fa-facebook" aria-hidden="true"></i>
								</a>
							</li>
						)}
					</ul>
				)}
			</div>
		</div>
	);
};

interface Props {
	speakers: Speaker[];
}

export const SpeakersBlock = ({ speakers }: Props) => {
	const event = useEvent();

	return (
		<section
			id="event-speakers"
			className={`${event.template.speakers_block ? '' : 'hide'}`}
		>
			<div className="container section-container">
				<h2
					id="event-speakers-heading"
					className={`section-heading custom-font-title ${
						event.template.speakers_title === null ||
						event.template.speakers_title === ''
							? 'hide'
							: ''
					}`}
				>
					{event.template.speakers_title}
				</h2>

				<div className="bx-speakers custom-font-content">
					<div className="content-speakers">
						{speakers.map((speaker) => (
							<SpeakerCard key={speaker.id} speaker={speaker} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
};
