import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';
import { useParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { defaultTinymceOptions } from '../../../tinymce';
import { getServerErrorMessage, getTinyMceLang } from '../../../shared/utils';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { v4 as uuidv4 } from 'uuid';
import { TranslatedValue } from '../magicform/types';
import {
	setTranslation,
	translateWithDefaultFallback,
} from '../magicform/utils/i18n';
import { useDesign } from '../hooks/useDesign';
import { useBlocks } from '../components/editor/useBlocks';
import axios from 'axios';
import { useToast } from '../hooks/useToast';

export interface Block {
	id: string;
	element: { type: 'html'; content: TranslatedValue };
}

export interface CustomPage {
	id: string;
	event_id: string;
	short_id: string;
	slug: string;
	title: TranslatedValue;
	nav_title: TranslatedValue;
	blocks: Block[];
	public_page: boolean;
	display_nav: boolean;
	created: string;
	modified: string;
}

export const useUpdateCustomPageMutation = (shortId: string) => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (body: Partial<CustomPage>) => {
			const { data } = await api.updateCustomPage(shortId, body);
			return data as CustomPage;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['custom_pages'] });
		},
	});
};

const generateBlockId = () => {
	return uuidv4();
};

const BlockEditor = ({ customPage }: { customPage: CustomPage }) => {
	const { __, lang } = useTranslate();
	const toast = useToast();

	const { blocks, setBlocks, updateBlock } = useBlocks();

	const [blocksLoaded, setBlocksLoaded] = useState(false);
	useEffect(() => {
		if (customPage && !blocksLoaded) {
			if (customPage.blocks.length > 0) {
				setBlocks(customPage.blocks);
			} else {
				setBlocks([
					{
						id: generateBlockId(),
						element: {
							type: 'html',
							content: setTranslation(
								{},
								lang,
								'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla malesuada tellus vel volutpat scelerisque. Sed at augue in sem hendrerit varius. Sed ut felis non nulla viverra tristique. Vestibulum pellentesque luctus leo sit amet gravida. Etiam condimentum consequat bibendum. Donec egestas vel ligula quis vestibulum. Vestibulum eget ultrices ipsum. Mauris eros libero, maximus ac dui imperdiet, volutpat condimentum neque. Aliquam nec suscipit leo, non congue erat. Curabitur eu erat sit amet magna pretium dictum non ut tellus. Nulla enim tellus, finibus a tincidunt sed, commodo eu libero.'
							),
						},
					},
				]);
			}
			setBlocksLoaded(true);
		}
	}, [setBlocks, blocksLoaded, customPage, lang]);

	const getTranslation = (translatedValue: TranslatedValue) => {
		return translateWithDefaultFallback(translatedValue, lang);
	};

	return (
		<div>
			{blocks.map((block) => {
				let element = null;
				switch (block.element.type) {
					case 'html':
						element = (
							<Editor
								value={getTranslation(block.element.content)}
								onEditorChange={(value) => {
									updateBlock(block.id, {
										...block,
										element: {
											type: 'html',
											content: setTranslation(
												block.element.content,
												lang,
												value
											),
										},
									});
								}}
								plugins={'charmap code lists link image'}
								toolbar={[
									'undo redo | blocks | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
									'forecolor backcolor | fontsize | bold italic underline strikethrough subscript superscript charmap | link unlink image | code',
								]}
								inline
								licenseKey="gpl"
								init={{
									...defaultTinymceOptions,
									content_style: undefined,
									language: getTinyMceLang(lang),
									menubar: false,
									block_formats: 'Heading 3=h3;Paragraph=p',
									paste_as_text: true,
									paste_remove_styles: true,
									paste_remove_spans: true,
									paste_remove_class_attributes: 'all',
									convert_urls: false,
									statusbar: false,
									height: 800,
									auto_focus: true,
									fixed_toolbar_container: `#toolbar-${block.id}`,
									toolbar_persist: true,
									paste_data_images: false,
									file_picker_types: 'image',
									file_picker_callback: function (callback, value, meta) {
										if (meta.filetype === 'image') {
											const input = document.createElement('input');
											input.setAttribute('type', 'file');

											input.addEventListener('change', () => {
												const file = (input.files as FileList)[0];
												const formData = new FormData();

												formData.append('file', file, file.name);

												axios
													.post('/admin/uploads/tinymceImageUpload', formData)
													.then(({ data }) => {
														callback(data.location);
													})
													.catch((err) => {
														const message = getServerErrorMessage(
															err,
															__('Unknown error.')
														);

														toast(message, 'danger');
													});
											});
											// Opening dialog
											input.click();
										}
									},
								}}
							/>
						);
				}

				return (
					<div key={block.id}>
						<div
							id={`toolbar-${block.id}`}
							style={{
								position: 'sticky',
								top: '50px',
								marginBottom: '20px',
								zIndex: 900,
							}}
						></div>
						{element}
					</div>
				);
			})}
		</div>
	);
};

const Page = () => {
	const { lang } = useTranslate();
	const api = useApi();
	const { shortid, slug } = useParams() as { shortid: string; slug: string };
	const queryClient = useQueryClient();

	const customPageQuery = useQuery({
		queryKey: ['custom_pages', shortid],
		queryFn: async () => {
			const { data } = await api.fetchCustomPage(shortid);
			return data as CustomPage;
		},
		initialData: () => {
			return queryClient
				.getQueryData<CustomPage[]>(['custom_pages'])
				?.find((d) => d.short_id === shortid);
		},
		initialDataUpdatedAt: () => {
			return queryClient.getQueryState(['custom_pages'])?.dataUpdatedAt;
		},
		staleTime: 1000,
	});

	const getTranslation = (translatedValue: TranslatedValue) => {
		return translateWithDefaultFallback(translatedValue, lang);
	};

	useDocTitle(
		customPageQuery.data ? getTranslation(customPageQuery.data.title) : slug
	);

	const { isDesignMode } = useDesign();

	return (
		<section>
			{customPageQuery.isSuccess && (
				<div className="container section-container">
					{customPageQuery && (
						<h2 className="section-heading custom-font-title">
							{getTranslation(customPageQuery.data.title)}
						</h2>
					)}

					{!isDesignMode ? (
						customPageQuery.data.blocks.map((block) => {
							let element = null;
							switch (block.element.type) {
								case 'html':
									element = (
										<div
											dangerouslySetInnerHTML={{
												__html: getTranslation(block.element.content),
											}}
										></div>
									);
							}

							return <div key={block.id}>{element}</div>;
						})
					) : (
						<BlockEditor customPage={customPageQuery.data} />
					)}
				</div>
			)}
		</section>
	);
};

export const CustomPage = () => {
	const { shortid } = useParams() as { shortid: string };

	return <Page key={shortid} />;
};
