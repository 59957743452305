import FormGroup from '../../../../shared/components/FormGroup';
import { useForm } from 'react-hook-form';
import { getCakeLang } from '../../../../i18n';
import { canSubmit, getFullName } from './PaymentForm';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { User } from '../types';

const getInitialValues = (user: User | undefined) => {
	return {
		name: getFullName(user),
		siret: '',
		address: '',
		zip: '',
		city: '',
		terms: false,
	};
};

export type ExternalPayementFormData = ReturnType<typeof getInitialValues>;

interface Props {
	content: string;
	onSubmit: (formValues: ExternalPayementFormData) => void;
	processing: boolean;
	user: User | undefined;
}

const ExternalPaymentCard = ({
	content,
	onSubmit,
	processing,
	user,
}: Props) => {
	const { __, lang } = useTranslate();

	const { register, handleSubmit, watch } = useForm({
		defaultValues: getInitialValues(user),
	});

	const terms = watch('terms');
	const name = watch('name');
	const address = watch('address');
	const zip = watch('zip');
	const city = watch('city');

	return (
		<div>
			<p
				className="text-center"
				style={{ fontWeight: 'bold', margin: '30px 0' }}
			>
				{__('Follow procedure below')}
			</p>

			<div dangerouslySetInnerHTML={{ __html: content }} />

			<>
				<div className="text-center" style={{ marginBottom: 35 }}>
					<p>
						<span style={{ fontWeight: 'bold' }}>
							{__('Fill in your informations')}
						</span>
						<br />
						<span style={{ color: '#737373', fontSize: '14px' }}>
							{__('These informations will appear in the invoice')}
						</span>
					</p>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					<FormGroup id="name" name="name">
						<label htmlFor="name">
							{__('Full name')} <span className="required">*</span>
						</label>
						<input type="text" className="form-control" {...register('name')} />
					</FormGroup>
					<FormGroup id="siret" name="siret">
						<label htmlFor="siret">{__('SIRET number')} </label>
						<input
							type="text"
							className="form-control"
							{...register('siret')}
						/>
					</FormGroup>
					<FormGroup id="address" name="address">
						<label htmlFor="address">
							{__('Address')} <span className="required">*</span>
						</label>
						<input
							type="text"
							className="form-control"
							{...register('address')}
						/>
					</FormGroup>
					<div className="row">
						<div className="col-md-6">
							<FormGroup id="zip" name="zip">
								<label htmlFor="zip">
									{__('Zip')} <span className="required">*</span>
								</label>
								<input
									type="text"
									className="form-control"
									{...register('zip')}
								/>
							</FormGroup>
						</div>
						<div className="col-md-6">
							<FormGroup id="city" name="city">
								<label htmlFor="city">
									{__('City')} <span className="required">*</span>
								</label>
								<input
									type="text"
									className="form-control"
									{...register('city')}
								/>
							</FormGroup>
						</div>
					</div>
					<FormGroup id="terms" name="terms">
						<div className="checkbox">
							<label htmlFor="terms">
								<input type="checkbox" id="terms" {...register('terms')} />
								{__(
									'Click here to indicate that you have read and agree to the terms presented in the'
								)}{' '}
								<a
									href={`/files/legal/tos_ticketing_${getCakeLang(lang)}.pdf`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{__('terms of use')}
								</a>
								.<span className="required">*</span>
							</label>
						</div>
					</FormGroup>
					<div style={{ fontStyle: 'italic' }}>
						<span className="required">*</span> {__('Required field')}
					</div>
					<div style={{ textAlign: 'center' }}>
						<button
							type="submit"
							className={`btn btn-ticketing-2 btn-primary ${
								processing ? 'btn-loading' : ''
							}`}
							disabled={
								!canSubmit(processing, {
									terms,
									name,
									address,
									zip,
									city,
								})
							}
						>
							{__('Complete order')}
						</button>
					</div>
				</form>
			</>
		</div>
	);
};

export default ExternalPaymentCard;
