import { useFormQuery } from '../hooks/useFormQuery';
import { useSubmitForm } from '../hooks/useSubmitForm';
import {
	Config,
	GroupFromServer,
	QuestionFromServer,
	QuestionToServer,
	SubmitError,
	TermsChecked,
} from '../types';
import { useState, useEffect, useCallback } from 'react';
import { produce } from 'immer';
import TermsAndConditions from './TermsAndConditions';
import { validate } from '../utils/validator';
import Question from './Question';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	findQuestionById,
	areQuestionConditionsFulfilled,
} from '../utils/checkConditions';
import { translate } from '../utils/i18n';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useApi } from '../../hooks/useApi';
import { User } from '@invityou/types';
import { useNavigate } from 'react-router-dom';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';

const format = (question: QuestionFromServer): QuestionToServer => {
	const answer = question.answer;

	const tmp = {
		...question,
		type: 'question' as const,
		options: question.options,
		inputType: question.input_type_id,
		answer:
			typeof answer === 'number'
				? answer.toString()
				: answer !== undefined
					? answer
					: null,
	};

	// Rename input_type_id -> inputType
	delete (tmp as any).input_type_id;

	// Option -> options
	delete (tmp as any).Option;

	return tmp;
};

// FORMAT DATA ACCORDINGLY TO THE OLD ANGULAR-READY CONTROLLER, HARD TODO
const formatDataBeforePost = (items: GroupFromServer[]): QuestionToServer[] => {
	let tmp: QuestionToServer[] = [];

	// All answered questions
	for (const item of items) {
		if (item.name === 'nogroup') {
			for (const question of item.questions) {
				tmp.push(format(question));
			}
		} else {
			for (const question of item.questions) {
				if (areQuestionConditionsFulfilled(question, items)) {
					tmp.push(format(question));
				}
			}
		}
	}

	// Delete section titles
	tmp = tmp.filter((question) => question.inputType !== 'section-title');

	return tmp;
};

const orderQuestions = (items: GroupFromServer[]) => {
	const questionsWithPositions: QuestionFromServer[] = [];

	for (const group of items) {
		for (const question of group.questions) {
			questionsWithPositions.push(question);
		}
	}

	questionsWithPositions.sort((a, b) => a.position - b.position);

	return questionsWithPositions;
};

export const MagicForm = ({ config }: { config: Config }) => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const formQuery = useFormQuery(config);
	const submitFormMutation = useSubmitForm(config);
	const api = useApi();
	const queryClient = useQueryClient();
	const uploadFormFileMutation = useMutation({
		mutationFn: (file: File) =>
			api.formUploadFile(file, config.formId, config.userId),
	});
	const [items, setItems] = useState<GroupFromServer[]>([]);
	const [itemsErrors, setItemsErrors] = useState<Record<string, string>>({});
	const [filesToUpload, setFilesToUpload] = useState<string[]>([]);
	const [areTermsChecked, setAreTermsChecked] = useState<TermsChecked>({
		terms: false,
		privacy: false,
	});
	const [submitError, setSubmitError] = useState<SubmitError | null>(null);
	// Set received questions
	useEffect(() => {
		// Wait for data to be fetched
		if (!formQuery.data) {
			return;
		}

		// Only set items once
		if (items.length > 0) {
			return;
		}

		setItems(formQuery.data.groups);
	}, [items, formQuery.data]);

	const questionsWithPos = orderQuestions(items);

	const navigate = useNavigate();

	const submit = (e: React.MouseEvent) => {
		e.preventDefault();
		const errors = validate(
			items,
			areTermsChecked,
			!!(formQuery.data?.terms && formQuery.data.terms !== ''),
			config
		);
		if (Object.keys(errors).length > 0) {
			setItemsErrors(errors);
			return;
		}
		setSubmitError(null);
		const formattedData = formatDataBeforePost(items);

		(async () => {
			try {
				// Upload files
				const filesToBeUploaded: File[] = [];
				for (const questionId of filesToUpload) {
					const question = findQuestionById(questionId, items);
					if (question && question.file) {
						filesToBeUploaded.push(question.file);
					}
				}

				const responses = await Promise.all(uploadFormFiles(filesToBeUploaded));
				for (const [i, questionId] of filesToUpload.entries()) {
					for (const question of formattedData) {
						if (question.id === questionId) {
							question.answer = responses[i].data.url;
						}
					}
				}

				const HAS_DECLINED = 1;
				const HAS_CANCELLED = 6;

				// Send answers
				const { data } = await submitFormMutation.mutateAsync(formattedData);
				queryClient.invalidateQueries({ queryKey: ['event_auth'] });
				const user = data.user as User;
				const companions = data.companions as boolean;
				const recommendations = data.recommendations as boolean;

				const formId = formQuery.data?.id;
				const formType = formQuery.data?.type;

				// eslint-disable-next-line unicorn/prefer-switch
				if (formType === 'inscription') {
					const isNotParticipating =
						user.status === HAS_DECLINED || user.status === HAS_CANCELLED;

					if (companions || recommendations) {
						const query =
							companions && recommendations
								? 'companions=true&recommendations=true'
								: companions
									? 'companions=true'
									: recommendations
										? 'recommendations=true'
										: null;

						navigate(
							`${prefix}/register/other${query ? `?${query}` : ''}#start`
						);
					} else if (isNotParticipating) {
						navigate(`${prefix}/register/success?formId=${formId}`);
					} else {
						navigate(`${prefix}/ticketing?formId=${formId}#start`);
					}
				} else if (formType === 'survey') {
					navigate(`${prefix}/survey/success?formId=${formId}`);
				} else if (formType === 'companions') {
					navigate(`${prefix}/register/other?companions=true#start`);
				}
			} catch (err) {
				setSubmitError(err?.response?.data ?? err);
			}
		})();
	};

	const uploadFormFiles = (files: File[]) => {
		const promises = [];

		for (const file of files) {
			const promise = uploadFormFileMutation.mutateAsync(file);
			promises.push(promise);
		}

		return promises;
	};

	const setFileToUpload = (file: File, question: QuestionFromServer) => {
		setFilesToUpload((filesToUpload) => [...filesToUpload, question.id]);
		handleMutation(question, file);
	};

	const handleMutation = useCallback(
		(
			question: QuestionFromServer,
			newAnswer: string | number | Record<string, boolean> | File
		) => {
			setItems(
				produce((draftItems) => {
					const groupIndex = draftItems.findIndex(
						(x) => x.id === question.group_id
					);

					if (groupIndex !== -1) {
						const questionIndex = draftItems[groupIndex].questions.findIndex(
							(q) => q.id === question.id
						);

						if (questionIndex !== -1) {
							if (newAnswer instanceof File) {
								const file = newAnswer;
								draftItems[groupIndex].questions[questionIndex].file = file;
							} else {
								draftItems[groupIndex].questions[questionIndex].answer =
									newAnswer;
							}
						}
					}
				})
			);
		},
		[]
	);

	const checkTerms = (areTermsCheckedValue: TermsChecked): void => {
		setAreTermsChecked(areTermsCheckedValue);
	};

	return (
		<>
			{formQuery.isPending && (
				<div className="magic-form-loader">
					<p>{__('Form loading...')}</p>
					<p>
						<span className="spinner"></span>
					</p>
				</div>
			)}

			{formQuery.isSuccess && (
				<div className="magic-form">
					<div>
						<form className="form" name="magicForm">
							<h2 className="form-title">
								{translate(formQuery.data.title, config.lang) ||
									__('Register form')}
							</h2>

							{questionsWithPos
								.filter(
									(question) =>
										!question.hidden &&
										areQuestionConditionsFulfilled(question, items)
								)
								.map((question) => (
									<Question
										question={question}
										config={config}
										key={question.id}
										handleMutation={handleMutation}
										itemsErrors={itemsErrors}
										setFileToUpload={setFileToUpload}
										submitError={submitError}
									/>
								))}

							{!config.isFormDisabled && (
								<TermsAndConditions
									termsUrl={formQuery.data.terms}
									checkTerms={checkTerms}
									areTermsChecked={areTermsChecked}
									config={config}
									itemsErrors={itemsErrors}
								/>
							)}

							{Object.keys(itemsErrors).length > 0 && (
								<div className="alert alert-danger">
									{__(
										'Your form is not complete. Please check it and try submitting it again.'
									)}
								</div>
							)}

							{submitError &&
								(submitError.details ? (
									<div className="alert alert-danger">
										{submitError.details[Object.keys(submitError.details)[0]]}
									</div>
								) : (
									<div className="alert alert-danger">
										{submitError.message}
									</div>
								))}

							{!config.isFormDisabled && items && items.length > 0 && (
								<div className="form-action">
									<button
										className={`btn btn-primary ${
											submitFormMutation.isPending ||
											uploadFormFileMutation.isPending
												? 'btn-loading'
												: ''
										}`}
										onClick={(e) => {
											submit(e);
										}}
										type="submit"
									>
										{__('Next')}
									</button>
								</div>
							)}
						</form>
					</div>
				</div>
			)}
		</>
	);
};
