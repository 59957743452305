import { useTranslate } from '../../../shared/hooks/useTranslate';
import FormGroup from '../../../shared/components/FormGroup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../hooks/useApi';
import { Link, useNavigate } from 'react-router-dom';
import { useAbsolutePath } from '../hooks/useAbsolutePath';
import { useState } from 'react';
import { isAxiosError } from 'axios';
import { __ } from '../../../i18n';
import { Recommendation } from '@invityou/types/bin/api/Users';
import { useDocTitle } from '../../../shared/hooks/useDocTitle';

const convertCodeToMessage = (code: string | null) => {
	if (code === 'E_REOMMENDATION_FORM') {
		return __('There are errors in your anwers');
	}
	return code;
};

const getInitialValue = (recommendation?: Recommendation) => {
	const fields = {
		lastname: recommendation?.lastname ?? '',
		firstname: recommendation?.firstname ?? '',
		mail: recommendation?.mail ?? '',
		company: recommendation?.company ?? '',
		job_title: recommendation?.job_title ?? '',
	};

	return fields;
};

type RecommendationFormType = ReturnType<typeof getInitialValue>;

export const AddRecommendations = () => {
	const { __ } = useTranslate();

	useDocTitle(__('Add recommendation'));

	const { register, handleSubmit } = useForm({
		defaultValues: getInitialValue(),
	});
	const navigate = useNavigate();
	const { prefix } = useAbsolutePath();
	const [error, setError] = useState<string | null>(null);
	const [validationErrors, setValidationErrors] = useState<Record<
		string,
		any
	> | null>(null);
	const submit = (formData: RecommendationFormType) => {
		addRecMutation.mutate(formData);
	};
	const api = useApi();
	const queryClient = useQueryClient();

	const addRecMutation = useMutation({
		mutationFn: async (data: Record<string, any>) => {
			const res = await api.submitRecommendation(data);
			return res.data;
		},
		onMutate() {
			setError(null);
			setValidationErrors(null);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['userAndRecommendations'] });
			navigate(`${prefix}/register/other`);
		},
		onError: (err) => {
			if (isAxiosError(err)) {
				const invityouError = err.response?.data as unknown;
				if (
					typeof invityouError === 'object' &&
					invityouError !== null &&
					'details' in invityouError &&
					typeof invityouError.details === 'object' &&
					'code' in invityouError &&
					typeof invityouError.code === 'string'
				) {
					setError(invityouError.code);
					setValidationErrors(invityouError.details);
				} else {
					setError(__('Unknown error.'));
				}
			} else {
				setError(__('Unknown error.'));
			}
		},
	});

	return (
		<section className="section-long">
			<div className="container section-container">
				<div id="recommendations">
					<form
						onSubmit={handleSubmit(submit)}
						className="form"
						name="recommendations"
					>
						<h2 className="form-title">{__('Make a recommendation')}</h2>
						<FormGroup
							label={__('Last Name')}
							validationErrors={validationErrors}
							name="lastname"
							required
						>
							<div className="form-col">
								<input
									{...register('lastname', { required: true })}
									type="text"
									className="form-control"
								/>
							</div>
						</FormGroup>
						<FormGroup
							label={__('First Name')}
							validationErrors={validationErrors}
							name="firstname"
							required
						>
							<div className="form-col">
								<input
									{...register('firstname', { required: true })}
									type="text"
									className="form-control"
								/>
							</div>
						</FormGroup>
						<FormGroup
							label={__('Email')}
							validationErrors={validationErrors}
							name="mail"
							required
						>
							<div className="form-col">
								<input
									{...register('mail', { required: true })}
									type="email"
									className="form-control"
								/>
							</div>
						</FormGroup>
						<FormGroup
							label={__('Company')}
							validationErrors={validationErrors}
							name="company"
						>
							<div className="form-col">
								<input
									{...register('company')}
									type="text"
									className="form-control"
								/>
							</div>
						</FormGroup>
						<FormGroup
							label={__('Job title')}
							validationErrors={validationErrors}
							name="job_title"
						>
							<div className="form-col">
								<input
									{...register('job_title')}
									type="text"
									className="form-control"
								/>
							</div>
						</FormGroup>
						{error && (
							<div className="alert alert-danger ">
								{convertCodeToMessage(error)}
							</div>
						)}
						<div className="form-action">
							<Link
								to={`${prefix}/register/#start`}
								className="btn btn-secondary pull-left"
							>
								{__('Back to form')}
							</Link>

							<button
								type="submit"
								className={`btn btn-primary ${
									addRecMutation.isPending ? 'btn-loading' : ''
								}`}
								disabled={addRecMutation.isPending}
							>
								{__('Send')}
							</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};
