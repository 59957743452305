import { Image, Partner } from '@invityou/types';
import { useEvent } from '../hooks/useEvent';

interface Props {
	partners: Partner[];
}

export const PartnersBlock = ({ partners }: Props) => {
	const event = useEvent();

	return (
		<section
			id="event-partners"
			className={`${event.template.partners_block ? '' : 'hide'}`}
		>
			<div className="container section-container">
				<h2
					id="event-partners-heading"
					className="section-heading custom-font-title"
				>
					{event.template.partners_title}
				</h2>

				<ul className="bx-partners custom-font-content">
					{partners.map((partner) => {
						const logo = partner.logo as Image | null;

						const img = logo && (
							<img
								src={logo.url}
								className={
									event.params.singlePartnerImg
										? 'fo_partner_unique'
										: 'fo_partner'
								}
							/>
						);

						return (
							<li
								key={partner.id}
								className={`${event.params.singlePartnerImg ? 'unique' : ''}`}
							>
								{partner.link ? (
									<a
										href={partner.link}
										target="_blank"
										rel="noopener noreferrer"
									>
										{img}
									</a>
								) : (
									img
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</section>
	);
};
