import React from 'react';
import { useEvent } from '../hooks/useEvent';
import { Program } from '@invityou/types/src/api/Programs';
import { set } from 'date-fns';
import { useTranslate } from '../../../shared/hooks/useTranslate';

interface Props {
	program: Program[];
}

export const ProgramBlock = ({ program }: Props) => {
	const { lang } = useTranslate();
	const event = useEvent();

	const formatTime = (time: string) => {
		if (time === '') {
			return time;
		}

		const [hoursPart, minutesPart] = time.split(':');
		const timestamp = set(Date.now(), {
			hours: Number.parseInt(hoursPart, 10),
			minutes: Number.parseInt(minutesPart, 10),
		});
		return Intl.DateTimeFormat(lang, { timeStyle: 'short' }).format(timestamp);
	};

	return (
		<section
			id="event-program"
			className={`${event.template.program_block ? '' : 'hide'}`}
		>
			<div className="container section-container">
				<h2
					id="event-program-heading"
					className={`section-heading custom-font-title ${
						event.template.program_title ? '' : 'hide'
					}`}
				>
					{event.template.program_title}
				</h2>

				<ul className="program-sections custom-font-content" role="tablist">
					{program.map((section, index) => {
						return (
							<li
								key={index}
								className={`program-section ${index === 0 ? 'active' : ''}`}
							>
								<a href={`#section-${index}`} role="tab" data-toggle="tab">
									{section.title === '' ? index + 1 : section.title}
								</a>
							</li>
						);
					})}
				</ul>

				<div className="program-timeline custom-font-content">
					{program.map((section, index) => {
						return (
							<React.Fragment key={index}>
								<h4 className="program-section-title">
									{section.title === '' ? index + 1 : section.title}
								</h4>
								<div
									id={`section-${index}`}
									role="tabpanel"
									className={`section-timeline ${index === 0 ? 'active' : ''}`}
								>
									{section.periods.map((period, index) => {
										return (
											<div key={index} className="period">
												<div className="period-time">
													{formatTime(period.start)}
													{period.end !== ''
														? ` - ${formatTime(period.end)}`
														: ''}
												</div>
												<div className="period-info">
													<div className="period-title">{period.title}</div>
													<div
														className="period-content"
														dangerouslySetInnerHTML={{ __html: period.content }}
													></div>
												</div>
											</div>
										);
									})}
								</div>
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</section>
	);
};
