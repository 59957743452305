interface Props {
	label?: string;
	id?: string;
	name?: string;
	validationErrors?: any;
	required?: boolean;
	size?: 'large' | 'small';
	labelSize?: 'default' | 'large';
	checkbox?: React.ReactElement;
	children?: React.ReactNode;
}

const FormGroup: React.FC<Props> = ({
	label,
	id,
	name,
	validationErrors,
	required,
	size,
	labelSize = 'default',
	children,
	checkbox,
}) => (
	<div
		className={`form-group ${size === 'small' ? 'form-group-sm' : ''} ${
			validationErrors && name && validationErrors[name] ? 'has-error' : ''
		} ${required ? 'required' : ''} ${checkbox ? 'checkbox-switch' : ''}`}
	>
		{checkbox}

		{label && (
			<label
				htmlFor={id}
				className={`${required ? 'required' : ''} ${
					labelSize === 'large' ? 'h4' : ''
				} control-label`}
			>
				{label}
			</label>
		)}

		{children}
		{validationErrors && name && validationErrors[name] && (
			<div className="error-message">{validationErrors[name]}</div>
		)}
	</div>
);

export default FormGroup;
