import * as Sentry from '@sentry/browser';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Alert from './Alert';
import { useTranslate } from '../../../shared/hooks/useTranslate';

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
	const { __ } = useTranslate();

	return (
		<div className="container">
			<Alert type="danger">
				{/^Please load Stripe.js/.test(error.message)
					? __(`Please load Stripe.js`)
					: __('An error has occurred.')}
			</Alert>
		</div>
	);
};

interface Props {
	children: React.ReactNode;
}

const CustomErrorBoundary = ({ children }: Props) => {
	return (
		<ErrorBoundary
			FallbackComponent={ErrorPage}
			onError={(error, errorInfo) => {
				Sentry.withScope((scope) => {
					scope.setExtras({ componentStack: errorInfo.componentStack });
					Sentry.captureException(error);
				});
			}}
			onReset={() => {
				location.reload();
			}}
		>
			{children}
		</ErrorBoundary>
	);
};

export default CustomErrorBoundary;
